import React from 'react'
import Autocomplete from '@mui/material/Autocomplete'
import { TextField } from '@mui/material'
import { listValidations } from '../../../../../../redux/api/serviceApi/listValidations'

type LrMeasurementSelectProps = {
  value: string
  onChange: (value: string) => void
  disabled?: boolean
}

export const LrMeasurementSelect = ({
  value,
  onChange,
  disabled = false,
}: LrMeasurementSelectProps) => {
  const items = listValidations.LR_MEASUREMENT.permitted_values

  return (
    <Autocomplete
      disabled={disabled}
      size="small"
      value={value}
      onChange={(event, newValue: any) => onChange(newValue)}
      options={items}
      getOptionLabel={(option) => option}
      getOptionKey={(option) => option}
      style={{
        background: disabled ? '#F9FAFB' : '#fff',
      }}
      renderInput={(params) => (
        <TextField {...params} label="Measurement" placeholder="Select or search" />
      )}
      isOptionEqualToValue={(option, value) => option === value}
      sx={{ width: '100%' }}
      disableClearable
      freeSolo
    />
  )
}
