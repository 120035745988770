import {
  Alert,
  AlertTitle,
  Backdrop,
  Box,
  Button,
  CircularProgress,
  Divider,
  IconButton,
  InputAdornment,
  Modal,
  Stack,
  TextField,
  Typography,
} from '@mui/material'
import { useCallback, useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import { unwrapResult } from '@reduxjs/toolkit'
import { ServiceRequest } from '../../../../../models/service_request'
import { CreateOfferPayload } from '../../../../../models/offer'
import { createOffer } from '../../../../../redux/actions/offer'
import { useNavigate } from 'react-router-dom'
import CloseIcon from '@mui/icons-material/Close'
import { APP_FONT } from '../../../../../constants/app_font'
import 'react-datepicker/dist/react-datepicker.css'
import './../styles.css'
import ModalTableHead from '../components/table_head'
import { RecommendedServicePart } from './../service.model'
import { useGetParentService } from '../../../../../hooks/useGetParentService'
import { OfferBlockService, OfferServiceBlock } from './OfferServiceBlock'
import { useAppSelector } from '../../../../../redux/store'
import { formatPrice, sanitizeNumber } from '../../../../../components/helper/helper'
import { validateOfferServiceFields } from '../service.validation'
import { calculateSubTotalPrice } from '../calculation'
import InvoiceNumberInput from '../../../components/invoice_number'

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  bgcolor: '#FFFFFF',
  maxWidth: '90vw',
  maxHeight: '90vh',
  overflowY: 'auto',
  borderRadius: '4px',
  minWidth: '50vw',
  minHeight: '50vh',
}

interface Props {
  setOpen: (arg: boolean) => void
  open: boolean
  onHide: () => void
  service_request: ServiceRequest
  selectedTimeblock: string | undefined
  setSelectedTimeblock: (arg: any) => void
  alert: boolean
  setAlert: (arg: boolean) => void
}

export interface ServicePrices {
  [serviceId: string]: string
}

export default function OfferModal({
  setOpen,
  open,
  setAlert,
  alert,
  onHide,
  service_request,
}: Props) {
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const [alertText, setAlertText] = useState(
    'Please select a timeblock and offer value for the offer.'
  )
  const [alertOpen, setAlertOpen] = useState(false)
  const [isLoading, setLoading] = useState(true)

  const [fee, setFee] = useState<string>()
  const [totalFee, setTotalFee] = useState<string>()

  const [invoiceNumber, setInvoiceNumber] = useState<string>('')

  const [servicePrices, setServicePrices] = useState<ServicePrices>({})
  const [shopSupplyFees, setShopSupplyFees] = useState<string>('')
  const [tax, setTax] = useState<string>('')
  const [services, setServices] = useState<OfferBlockService[]>([])
  const [isFormValid, setIsFormValid] = useState(false)

  const laborRate = useAppSelector((state) => state.user)?.userInfo?.branch?.labor_rate ?? 0

  const { getParentService, isSuccess } = useGetParentService()

  useEffect(() => {
    const isValid = services.every((service) => {
      const parentService = getParentService(service.type || service.id)
      return validateOfferServiceFields(service, parentService)
    })

    setIsFormValid(isValid)
  }, [services])

  const handleAddServicePart = (serviceIndex: number) => {
    setServices((prevServices) =>
      prevServices.map((service, index) =>
        index === serviceIndex
          ? {
              ...service,
              parts: [
                ...service.parts,
                {
                  name: '',
                  quantity: 1,
                  price_per_unit: 0,
                  number: '',
                },
              ],
            }
          : service
      )
    )
  }

  const handleServicePartChange = (
    serviceIndex: number,
    partIndex: number,
    field: keyof RecommendedServicePart,
    value: string
  ) => {
    setServices((currentServices) =>
      currentServices.map((service, idx) => {
        if (idx === serviceIndex) {
          return {
            ...service,
            parts: service.parts.map((part, pIndex) => {
              if (pIndex === partIndex) {
                let newValue: any = value

                if (field === 'quantity') {
                  const inputValue = newValue.replace(/[^0-9]/g, '')
                  if (inputValue === '') {
                    newValue = 0
                  } else {
                    const numberValue = parseInt(inputValue)
                    newValue = numberValue
                  }
                } else if (field === 'price_per_unit') {
                  const input = value.replace(/[^0-9]/g, '')

                  const number = parseInt(input, 10) / 100

                  newValue = new Intl.NumberFormat('en-US', {
                    style: 'currency',
                    currency: 'USD',
                    minimumFractionDigits: 2,
                    maximumFractionDigits: 2,
                  })
                    .format(number)
                    .slice(1)
                }

                return { ...part, [field]: newValue }
              }
              return part
            }),
          }
        }
        return service
      })
    )
  }

  const handleServiceChange = (
    serviceIndex: number,
    field: keyof OfferBlockService | string,
    value: string | number | string[]
  ) => {
    setServices((prevServices) =>
      prevServices.map((service, index) =>
        index === serviceIndex
          ? {
              ...service,
              [field]: value,
            }
          : service
      )
    )
  }

  const handleRemoveServicePart = (serviceIndex: number, partIndex: number) => {
    setServices((prevServices) =>
      prevServices.map((service, index) =>
        index === serviceIndex
          ? {
              ...service,
              parts: service.parts.filter((_, pIndex) => pIndex !== partIndex),
            }
          : service
      )
    )
  }

  const handleAdditionalDataChange = useCallback(
    (index: number, field: string, value: string | string[] | number) => {
      //   @ts-ignore
      setServices((currentServices) =>
        currentServices.map((service, idx) => {
          if (idx === index) {
            return {
              ...service,
              additional_data: {
                ...service.additional_data,
                [field]: value,
              },
            }
          }
          return service
        })
      )
    },
    []
  )

  useEffect(() => {
    if (isSuccess) {
      const initialServices: OfferBlockService[] = []
      service_request.services.forEach((service) => {
        const serviceId = service.service_id
        const parentService = getParentService(serviceId, true)
        if (parentService) {
          initialServices.push({
            id: parentService.id,
            name: parentService.name,
            type: parentService.child?.id ?? null,
            types: parentService.children ?? null,
            serviceType: parentService.type,
            parts: [],
            price: 0,
            labor_hours: 0,
            labor_price: 0,
            labor_rate: laborRate,
            request_additional_data: service.additional_data,
            additional_data: service.additional_data,
            vehicle: service_request.vehicle,
          })
        }
      })
      if (initialServices.length > 0) {
        setServices(initialServices)
      }
      setLoading(false)
    }
  }, [service_request?.services, isSuccess])

  useEffect(() => {
    const initialPrices: ServicePrices = {}
    service_request.services.forEach((service: any) => {
      const price = service.service.type === 'DIAGNOSIS' ? '0.00' : ''
      initialPrices[service.service_id] = price
    })

    setServicePrices(initialPrices)
  }, [service_request])

  const handleOfferSubmission = () => {
    if (totalFee === undefined || service_request.requestedDateTime === undefined) {
      setAlert(true)
    } else {
      const providerId = localStorage.getItem('branchId')
      if (providerId) {
        setLoading(true)
        const servicesArray = services.map((service) => ({
          service_id: service.type || service.id,
          labor_hours: Number(service.labor_hours),
          parts: service.parts.map((part) => ({
            name: String(part.name),
            quantity: Number(part.quantity),
            price_per_unit: sanitizeNumber(part.price_per_unit),
            number: part.number,
          })),
          additional_data: service.additional_data,
        }))

        const offer: CreateOfferPayload = {
          ...(shopSupplyFees && { supply_fee: parseFloat(shopSupplyFees.replace(/,/g, '')) }),
          ...(tax && { tax: parseFloat(tax.replace(/,/g, '')) }),
          consumer_request_id: service_request.id,
          provider_branch_id: providerId,
          services: servicesArray,
          invoice_number: invoiceNumber.length > 0 ? invoiceNumber : undefined,
        }

        dispatch<any>(createOffer(offer))
          .then(unwrapResult)
          .then((offer: any) => {
            if (offer) {
              setOpen(false)
              navigate(0)
            }
          })
          .catch((error: any) => {
            setLoading(false)
            setAlert(true)
            setAlertText(error)
            console.log('error')
          })
      }
    }
  }

  useEffect(() => {
    const total = calculateSubTotalPrice(services)
    const formattedTotal = total.toFixed(2)
    setFee(formattedTotal)
  }, [services])

  useEffect(() => {
    function calculateTotalPrice() {
      const fees = parseFloat(shopSupplyFees.replace(/,/g, '')) || 0
      const taxes = parseFloat(tax.replace(/,/g, '')) || 0
      const subtotal = calculateSubTotalPrice(services)

      return (subtotal + fees + taxes).toFixed(2)
    }

    const total = calculateTotalPrice()
    setTotalFee(total)
  }, [fee, tax, shopSupplyFees])

  const handleFeeChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const input = event.target.value.replace(/[^0-9]/g, '')
    const number = parseInt(input, 10) / 100

    const formattedNumber = new Intl.NumberFormat('en-US', {
      style: 'currency',
      currency: 'USD',
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    })
      .format(number)
      .slice(1)

    setShopSupplyFees(formattedNumber)
  }

  const handleTaxChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const input = event.target.value.replace(/[^0-9]/g, '')
    const number = parseInt(input, 10) / 100

    const formattedNumber = new Intl.NumberFormat('en-US', {
      style: 'currency',
      currency: 'USD',
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    })
      .format(number)
      .slice(1)

    setTax(formattedNumber)
  }

  return (
    <Modal
      open={open}
      onClose={onHide}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
      disableAutoFocus={true}
      sx={{ overflow: 'hidden' }}
    >
      <>
        <Box sx={style}>
          {alert && <Alert severity="error">{alertText}</Alert>}
          <ModalTableHead
            onHide={onHide}
            service_request={{
              slug: service_request.slug,
              vehicle: service_request.vehicle,
              services: service_request.services,
              drop_off_time: service_request.requestedDateTime,
              is_diagnostic: service_request.type === 'DIAGNOSTIC',
              comment: service_request.additional_information ?? undefined,
              expires_at: service_request.expires_at,
            }}
          />
          <Box>
            {alertOpen && (
              <Box
                sx={{
                  display: 'flex',
                  justifyContent: 'space-around',
                  pt: 2,
                }}
              >
                <Alert
                  action={
                    <IconButton
                      aria-label="close"
                      color="inherit"
                      size="small"
                      onClick={() => {
                        setAlertOpen(false)
                      }}
                    >
                      <CloseIcon fontSize="inherit" />
                    </IconButton>
                  }
                  severity="warning"
                  sx={{
                    borderRadius: '12px',
                    borderColor: '#FEC84B',
                    borderWidth: '1px',
                    borderStyle: 'solid',
                  }}
                >
                  <AlertTitle>
                    <strong>The date you've selected for pick-up is a holiday.</strong>
                  </AlertTitle>
                  Ensure that your service team is available on this day for timely completion.
                </Alert>
              </Box>
            )}
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'space-around',
                alignItems: 'center',
                mb: 0,
                mt: 2,
              }}
            >
              <Stack
                spacing={1}
                sx={{
                  width: '90%',
                }}
              >
                <Typography
                  sx={{
                    fontSize: '24px',
                    fontFamily: APP_FONT,
                    fontWeight: '700',
                    textAlign: 'left',
                    mb: 2,
                  }}
                >
                  Your Offer
                </Typography>
                <InvoiceNumberInput
                  invoiceNumber={invoiceNumber}
                  onInvoiceNumberChange={setInvoiceNumber}
                />
                {services.map((service, index) => (
                  <OfferServiceBlock
                    service={service}
                    index={index}
                    handleServiceChange={handleServiceChange}
                    handleServicePartChange={handleServicePartChange}
                    handleAddServicePart={handleAddServicePart}
                    handleRemoveServicePart={handleRemoveServicePart}
                    handleAdditionalDataChange={handleAdditionalDataChange}
                  />
                ))}
                <Divider
                  sx={{
                    marginY: '12px !important',
                  }}
                />
                <Box display="flex" gap={4}>
                  <Typography
                    variant="h6"
                    sx={{
                      fontFamily: APP_FONT,
                      fontWeight: '600',
                      color: '#393A3D',
                      width: '100%',
                    }}
                  >
                    Subtotal:
                  </Typography>
                  <Typography
                    variant="h6"
                    sx={{
                      fontFamily: APP_FONT,
                      fontWeight: '600',
                      color: '#DB5D08',
                    }}
                  >
                    {formatPrice(fee ?? '')}
                  </Typography>
                </Box>
                <Divider
                  sx={{
                    marginY: '16px !important',
                  }}
                />
                <Box
                  sx={{
                    display: 'flex',
                    flexDirection: 'row',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                    mb: 2,
                  }}
                >
                  <Stack spacing={1}>
                    <Typography
                      sx={{
                        fontSize: '16px',
                        fontFamily: APP_FONT,
                        fontWeight: '600',
                        color: '#393A3D',
                      }}
                    >
                      Shop supply fees
                    </Typography>
                  </Stack>
                  <TextField
                    value={shopSupplyFees}
                    onChange={handleFeeChange}
                    InputProps={{
                      startAdornment: <InputAdornment position="start">$</InputAdornment>,
                      style: { background: '#fff' },
                    }}
                    inputProps={{
                      maxLength: 9,
                      style: { background: '#fff', height: '17px', textAlign: 'right' },
                    }}
                    style={{ width: '30%' }}
                  />
                </Box>
                <Box
                  sx={{
                    display: 'flex',
                    flexDirection: 'row',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                    mb: 2,
                  }}
                >
                  <Stack spacing={1}>
                    <Typography
                      sx={{
                        fontSize: '16px',
                        fontFamily: APP_FONT,
                        fontWeight: '600',
                        color: '#393A3D',
                      }}
                    >
                      Tax
                    </Typography>
                  </Stack>
                  <TextField
                    value={tax}
                    onChange={handleTaxChange}
                    InputProps={{
                      startAdornment: <InputAdornment position="start">$</InputAdornment>,
                      style: { background: '#fff' },
                    }}
                    inputProps={{
                      maxLength: 9,
                      style: { background: '#fff', height: '17px', textAlign: 'right' },
                    }}
                    style={{ width: '30%' }}
                  />
                </Box>
                <Divider
                  sx={{
                    marginY: '16px !important',
                  }}
                />
                <Box display="flex" gap={4}>
                  <Typography
                    variant="h6"
                    sx={{
                      fontFamily: APP_FONT,
                      fontWeight: '600',
                      color: '#393A3D',
                      width: '100%',
                    }}
                  >
                    Total:
                  </Typography>
                  <Typography
                    variant="h6"
                    sx={{
                      fontFamily: APP_FONT,
                      fontWeight: '600',
                      color: '#DB5D08',
                    }}
                  >
                    {formatPrice(totalFee ?? '')}
                  </Typography>
                </Box>
                <Box>
                  <Box
                    sx={{
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'center',
                      width: '100%',
                      marginTop: 3,
                    }}
                  >
                    <Button
                      disabled={isLoading || !isFormValid}
                      onClick={handleOfferSubmission}
                      type="submit"
                      fullWidth
                      variant="contained"
                      sx={{
                        borderRadius: 10,
                        marginTop: 3,
                        marginBottom: 3,
                        backgroundColor: '#FF6600',
                        height: 35,
                        width: 250,
                        ':hover': {
                          backgroundColor: 'black',
                        },
                      }}
                    >
                      <div
                        style={{
                          fontSize: 14,
                          fontFamily: APP_FONT,
                          fontWeight: '700',
                        }}
                      >
                        SUBMIT OFFER
                      </div>
                    </Button>
                  </Box>
                </Box>
              </Stack>
            </Box>
          </Box>
        </Box>
        {isLoading && (
          <Backdrop
            sx={{
              position: 'fixed',
              top: 0,
              left: 0,
              width: '100vw',
              height: '100vh',
              backgroundColor: 'rgba(0, 0, 0, 0.5)',
              zIndex: (theme) => theme.zIndex.drawer + 1,
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
            }}
            open={true}
            onClick={() => {}}
          >
            <CircularProgress
              size={60}
              sx={{
                color: '#FF6600',
              }}
            />
          </Backdrop>
        )}
      </>
    </Modal>
  )
}
