import { TextInput } from '@mantine/core'

const InvoiceNumberInput = ({
  invoiceNumber,
  onInvoiceNumberChange,
}: {
  invoiceNumber: string
  onInvoiceNumberChange: (string) => void
}) => {
  return (
    <TextInput
      size="md"
      label="Enter your invoice number (optional)"
      placeholder=""
      description="Any invoice identifier from your shop management software"
      maxLength={50}
      maw="425px"
      value={invoiceNumber}
      onChange={(event) => onInvoiceNumberChange(event.currentTarget.value)}
    />
  )
}

export default InvoiceNumberInput
