import React, { useCallback, useEffect, useRef, useState } from 'react'
import {
  Alert,
  Backdrop,
  Box as MaterialBox,
  Button,
  CircularProgress,
  Dialog,
  DialogContent,
  Divider,
  Grid,
  Stack as MaterialStack,
  Typography,
} from '@mui/material'
import CloudUploadIcon from '@mui/icons-material/CloudUpload'
import { submitTechnicalApproval, uploadPdf } from '../../../../../redux/actions/offer'
import { unwrapResult } from '@reduxjs/toolkit'
import { useDispatch } from 'react-redux'
import ModalTableHead from '../components/table_head'
import 'react-datepicker/dist/react-datepicker.css'
import { APP_FONT } from '../../../../../constants/app_font'
import {
  calculateOfferAmount,
  Offer,
  ProviderRequestServiceType,
} from '../../../../../models/offer_new'
import { useNavigate } from 'react-router-dom'
import { ReactComponent as DownloadIcon } from '../../../../../assets/images/download.svg'
import { ReactComponent as PdfDownloadIcon } from '../../../../../assets/images/pdf_download.svg'
import { ReactComponent as Close } from '../../../../../assets/images/close.svg'
import InspectionWithoutServicesModal from '../inspection_without_services'
import { formatPrice, sanitizeNumber } from '../../../../../components/helper/helper'
import ErrorHandler from '../../../error-handler'
import {
  useListRecServiceImagesByOfferQuery,
  useUploadRecServiceImagesMutation,
} from '../../../../../redux/api/serviceApi/serviceApi'
import { RecServiceBlock } from '../components/RecServiceBlock'
import {
  RecommendedService,
  RecommendedServicePart,
  GetServiceId,
  updateServicesWithExistingImages,
} from './../service.model'
import { ServiceValidationKey } from '../../../../../redux/api/serviceApi/listValidations'
import { useGetParentService } from '../../../../../hooks/useGetParentService'
import { ReactComponent as AddIcon } from '../../../../../assets/images/add_icon.svg'
import { ReactComponent as PlusIcon } from '../../../../../assets/images/plus_white.svg'
import ArrowForwardIcon from '@mui/icons-material/ArrowForwardIos'
import { validateRecommendedServiceFields } from '../service.validation'
import { calculateSubTotalPrice } from '../calculation'
import { statusText } from '../../offer-status'
import { OfferServiceImage } from '../../../../../redux/api/serviceApi/serviceApi.types'
import SubmitShopSupplyFeesAndTax from '../components/SubmitShopSupplyFeesAndTax'
import { Box, Stack } from '@mantine/core'
import InvoiceNumberInput from '../../../components/invoice_number'
import RequestedServiceInfoBlock from '../components/RequestedService'

const MAX_FILE_SIZE = 25 * 1024 * 1024

export interface ServiceChild {
  id: string
  name: string
  recommend_required_data: ServiceValidationKey[] | null
  request_required_data: ServiceValidationKey[] | null
}

export interface Service {
  id: string
  name: string
  children: ServiceChild[]
  recommend_required_data: ServiceValidationKey[] | null
  request_required_data: ServiceValidationKey[] | null
  is_photo_upload_required: boolean
}

interface InspectionUploadModalProps {
  open: boolean
  onClose: () => void
  onSubmit: () => void
  offer: Offer
  services: Service[]
  requestedServices: ProviderRequestServiceType[]
}

interface ServicePrices {
  [serviceId: string]: string
}

type UploadStatus = 'IDLE' | 'UPLOADING' | 'SUCCESS' | 'ERROR'

const InspectionUploadModal: React.FC<InspectionUploadModalProps> = ({
  open,
  onClose,
  onSubmit,
  offer,
  services,
  requestedServices,
}) => {
  const dispatch = useDispatch()

  const [error, setError] = useState<string | null>(null)
  const [fileWorkOrder, setFileWorkOrder] = useState<File | null>(null)
  const [fileInspection, setFileInspection] = useState<File | null>(null)
  const [filePmi, setFilePmi] = useState<File | null>(null)
  const [uploadStatusPmi, setUploadStatusPmi] = useState<UploadStatus>('IDLE')
  const [uploadStatusInspection, setUploadStatusInspection] = useState<UploadStatus>('IDLE')
  const [uploadStatusWorkOrder, setUploadStatusWorkOrder] = useState<UploadStatus>('IDLE')
  const navigate = useNavigate()
  const [recServices, setRecServices] = useState<RecommendedService[]>([])
  const fileInputInspectionRef = useRef<HTMLInputElement>(null)
  const fileInputPmiRef = useRef<HTMLInputElement>(null)
  const [servicePrices, setServicePrices] = useState<ServicePrices>({})
  const [fee, setFee] = useState<string>()
  const [totalFee, setTotalFee] = useState<string>()
  const [shopSupplyFees, setShopSupplyFees] = useState<string>('')
  const [tax, setTax] = useState<string>('')
  const [alertOpen, setAlertOpen] = useState(false)
  const [alertText, setAlertText] = useState('')
  const [inspectionFeedbackFile, setInspectionFeedbackFile] = useState<any | null>(null)
  const [isLoading, setIsLoading] = useState(false)
  const [isInspectionWithoutServicesModalOpen, setInspectionWithoutServicesModalOpen] =
    useState(false)
  const [showAdditionalServices, setShowAdditionalServices] = useState(false)
  const [subtotalRequestedServices, setSubtotalRequestedServices] = useState<string>('')
  const [enteringShopSupplyAndTaxEntry, setEnteringShopSupplyAndTaxEntry] = useState(false)
  const [invoiceNumber, setInvoiceNumber] = useState<string>(offer.invoice_number || '')

  const clearError = () => setError(null)

  const handleUploadClick = (type: string) => {
    type === 'WORK_ORDER'
      ? fileInputInspectionRef.current?.click()
      : fileInputPmiRef.current?.click()
  }

  const { data: existingFilesResponse, refetch: refetchExistingFiles } =
    useListRecServiceImagesByOfferQuery({
      offer_id: offer.id,
    })

  useEffect(() => {
    if (error) {
      console.error('Error:', error)
      setAlertOpen(true)
      setAlertText(`${error}`)
    }
  }, [error])

  useEffect(() => {
    if (existingFilesResponse) {
      const existingFiles = existingFilesResponse.items
      setRecServices((currentRecServices) =>
        currentRecServices.map((recService) => {
          const existingServiceImages = existingFiles.filter(
            (file: OfferServiceImage) =>
              file.service_id === GetServiceId(recService) ||
              file.offer_service_id === recService.offer_service_id
          )
          return {
            ...recService,
            uploadedImageFiles: existingServiceImages,
          }
        })
      )
    }
  }, [existingFilesResponse])

  const { isSuccess, getParentService } = useGetParentService()

  useEffect(() => {
    if (isSuccess) {
      const value = offer?.services
        .filter((service) => !service.is_approved)
        .map((service) => {
          const serviceId = service.service.id
          let parentService = getParentService(serviceId)
          if (!services.some((s) => s.id === parentService?.id)) {
            parentService = null
          }
          const matchingService = services.find(
            (s) => s.id === service.service.parent?.id || s.id === service.service.id
          )

          if (matchingService) {
            const formattedNumber = new Intl.NumberFormat('en-US', {
              style: 'currency',
              currency: 'USD',
              minimumFractionDigits: 2,
              maximumFractionDigits: 2,
            })
              .format(parseFloat(service.price))
              .slice(1)

            setServicePrices((prevPrices) => ({
              ...prevPrices,
              [`${serviceId}_${service.service.id}`]: formattedNumber,
            }))

            const newRecService: RecommendedService = {
              id: parentService?.id ?? service.service.id,
              name: parentService?.name ?? service.service.name,
              type: parentService?.child?.id ?? null,
              types: parentService?.children ?? [],
              price: parseFloat(service.price),
              serviceType: service.service.type,
              parts:
                service.parts.map((part) => ({
                  name: part.name,
                  quantity: part.quantity,
                  price_per_unit: part.price_per_unit,
                  number: part.number,
                })) || [],
              labor_hours: service.labor_hours,
              labor_price: (offer.labor_rate ?? 0) * service.labor_hours,
              imageFiles: [],
              uploadedImageFiles: [],
              additional_data: service.additional_data,
              offer_service_id: service.id,
              ...service.additional_data,
            }

            const existingFiles = existingFilesResponse?.items ?? []

            const existingServiceImages = existingFiles.filter(
              (file: OfferServiceImage) =>
                file.service_id === service.service.id ||
                file.offer_service_id === GetServiceId(newRecService)
            )

            newRecService.uploadedImageFiles = existingServiceImages

            return newRecService
          }
          return null
        })
        .filter((service) => service !== null)

      setRecServices((prev) => {
        if (prev.length === 0) {
          if (value.length > 0) {
            setShowAdditionalServices(true)
          }
          return value
        }
        return prev
      })
    }
  }, [
    offer.services,
    services,
    isSuccess,
    getParentService,
    offer.labor_rate,
    existingFilesResponse,
  ])

  useEffect(() => {
    const newTotal = calculateSubTotalPrice(recServices, offer.labor_rate ?? 0)
    const formattedTotal = newTotal.toFixed(2)
    setFee(formattedTotal)
  }, [servicePrices, recServices, offer.services, offer.labor_rate])

  useEffect(() => {
    function calculateTotalPrice() {
      const fees = parseFloat(shopSupplyFees?.replace(/,/g, '')) || 0
      const taxes = parseFloat(tax?.replace(/,/g, '')) || 0
      let requestedServicesTotal = offer.services
        .filter((service) => service.is_approved)
        .reduce((total, service) => {
          return total + sanitizeNumber(service.price)
        }, 0)

      const recommendedServicesTotal = calculateSubTotalPrice(recServices, offer.labor_rate ?? 0)
      requestedServicesTotal = Math.ceil(requestedServicesTotal * 100) / 100

      return (recommendedServicesTotal + fees + taxes + requestedServicesTotal).toFixed(2)
    }

    const total = calculateTotalPrice()
    setTotalFee(total)
  }, [
    recServices,
    servicePrices,
    shopSupplyFees,
    fee,
    tax,
    subtotalRequestedServices,
    offer.services,
    offer.labor_rate,
  ])

  useEffect(() => {
    if (offer?.files) {
      const feedbackFile = offer.files.find((file) => file.type === 'WORK_ORDER_FEEDBACK')
      setInspectionFeedbackFile(feedbackFile || null)
    }
  }, [offer])

  useEffect(() => {
    const calculateSubtotalRequestedServices = () => {
      const laborCost = offer.services
        .filter((service) => service.is_approved)
        .reduce((total, service) => total + (service.price ? parseFloat(service.price) : 0), 0)

      const subtotal =
        laborCost + (parseFloat(offer.tax) || 0) + (parseFloat(offer.supply_fee) || 0)
      setSubtotalRequestedServices(subtotal.toFixed(2))
    }

    calculateSubtotalRequestedServices()
  }, [offer.services, offer.labor_rate, offer.tax, offer.supply_fee])

  const handleFileSelect = (selectedFile: File, fileType: string) => {
    if (selectedFile.size > MAX_FILE_SIZE) {
      setAlertOpen(true)
      setAlertText('File is too large. Maximum size is 10MB.')
      return
    }
    if (fileType === 'WORK_ORDER') {
      setFileWorkOrder(selectedFile)
    } else if (fileType === 'PMI') {
      setFilePmi(selectedFile)
    } else if (fileType === 'INSPECTION') {
      setFileInspection(selectedFile)
    }
    fileType === 'WORK_ORDER' ? setFileWorkOrder(selectedFile) : setFilePmi(selectedFile)
    setUploadStatusWorkOrder('IDLE')
  }

  const handleUpload = async () => {
    if (isPmi) {
      await handleUploadPmi()
    } else {
      await handleUploadInspection()
      await handleSubmit()
    }
  }

  const handleUploadInspection = async () => {
    if (!fileInspection || !offer.id) {
      console.error('No file or offerId provided.')
      setUploadStatusInspection('ERROR')
      return
    }

    setUploadStatusInspection('UPLOADING')
    const formData = new FormData()
    formData.append('file', fileInspection)

    dispatch<any>(uploadPdf({ offerId: offer.id, fileType: 'INSPECTION', formData }))
      .then(unwrapResult)
      .then((data: any) => {
        setUploadStatusInspection('SUCCESS')
      })
      .catch((error: any) => {
        console.error('Error uploading file:', error)
        setUploadStatusInspection('ERROR')
        setError(`Upload failed: ${error.message}`)
        setAlertText(error.message)
      })
  }

  const handleUploadPmi = async () => {
    if (!filePmi || !offer.id) {
      console.error('No file or offerId provided.')
      setUploadStatusPmi('ERROR')
      return
    }
    setUploadStatusPmi('UPLOADING')
    const formData = new FormData()
    formData.append('file', filePmi)

    dispatch<any>(uploadPdf({ offerId: offer.id, fileType: 'PMI', formData }))
      .then(unwrapResult)
      .then((data: any) => {
        setUploadStatusPmi('SUCCESS')
        handleSubmit()
      })
      .catch((errorMessage: any) => {
        console.error('Error uploading file:', errorMessage)
        setUploadStatusPmi('ERROR')
        setError(errorMessage ?? 'ERROR on file upload')
        setAlertText(errorMessage ?? 'ERROR on file upload')
      })
  }

  const uploadFeedback = () => {
    switch (uploadStatusWorkOrder || uploadStatusInspection || uploadStatusPmi) {
      case 'UPLOADING':
        return <Typography color="primary">Uploading...</Typography>
      case 'SUCCESS':
        return <Typography color="green">Upload successful!</Typography>
      case 'ERROR':
        return <Typography color="error">Upload failed. Try again.</Typography>
      default:
        return null
    }
  }

  const renderDroppableArea = (fileType: string) => (
    <div
      onDragOver={(event) => event.preventDefault()}
      onDrop={(event) => {
        event.preventDefault()
        if (event.dataTransfer.files[0]) {
          if (fileType === 'WORK_ORDER') {
            handleFileSelect(event.dataTransfer.files[0], 'WORK_ORDER')
          } else if (fileType === 'PMI') {
            handleFileSelect(event.dataTransfer.files[0], 'PMI')
          } else if (fileType === 'INSPECTION') {
            handleFileSelect(event.dataTransfer.files[0], 'INSPECTION')
          }
        }
      }}
      style={{
        border: '2px dashed #C4C4C4',
        borderRadius: '4px',
        padding: '20px',
        textAlign: 'center',
        cursor: 'pointer',
        marginBottom: '16px',
        transition: 'background-color 0.3s',
        backgroundColor: '#FAFAFA',
      }}
    >
      <CloudUploadIcon style={{ fontSize: '50px', color: '#C4C4C4' }} />
      <Typography>
        <Button
          variant="text"
          component="span"
          style={{ textTransform: 'none', padding: 0 }}
          color="warning"
          onClick={() => handleUploadClick(fileType)}
        >
          Click to upload
        </Button>{' '}
        or drag and drop
      </Typography>
      <Typography variant="caption">PDF, PNG, JPG, or JPEG (max. 25 MB)</Typography>
      <input
        style={{ display: 'none' }}
        ref={fileType === 'WORK_ORDER' ? fileInputInspectionRef : fileInputPmiRef}
        id="file-input"
        type="file"
        accept="application/pdf, image/png, image/jpeg, image/jpg"
        onChange={(e) => {
          if (e.target.files?.[0]) {
            if (fileType === 'WORK_ORDER') {
              handleFileSelect(e.target.files[0], 'WORK_ORDER')
            } else if (fileType === 'PMI') {
              handleFileSelect(e.target.files[0], 'PMI')
            } else if (fileType === 'INSPECTION') {
              handleFileSelect(e.target.files[0], 'INSPECTION')
            }
          }
        }}
      />
      {fileType === 'WORK_ORDER' && fileWorkOrder && (
        <>
          <Typography variant="body2">{`Selected file: ${fileWorkOrder.name}`}</Typography>
          {uploadFeedback()}
        </>
      )}
      {fileType === 'PMI' && filePmi && (
        <>
          <Typography variant="body2">{`Selected file: ${filePmi.name}`}</Typography>
          {uploadFeedback()}
        </>
      )}
      {fileType === 'INSPECTION' && fileInspection && (
        <>
          <Typography variant="body2">{`Selected file: ${fileInspection.name}`}</Typography>
          {uploadFeedback()}
        </>
      )}
    </div>
  )

  const handleServiceChange = (
    index: number,
    field: keyof RecommendedService | string,
    value: string | string[] | number
  ) => {
    // @ts-ignore
    setRecServices((currentServices) => {
      const updatedServices = currentServices.map((service, idx) => {
        if (idx === index) {
          if (field === 'id') {
            const selectedService = services.find((s) => s.id === value)
            return {
              ...service,
              [field]: value,
              name: selectedService ? selectedService.name : '',
              type: null,
              types: selectedService ? selectedService.children : [],
              additional_data: {},
            }
          } else if (field === 'type') {
            return {
              ...service,
              [field]: value,
              additional_data: {},
            }
          } else {
            return { ...service, [field]: value }
          }
        }
        return service
      })
      //@ts-ignore
      return updateServicesWithExistingImages(existingFilesResponse?.items ?? [], updatedServices)
    })
  }

  const handleServicePartChange = (
    serviceIndex: number,
    partIndex: number,
    field: keyof RecommendedServicePart,
    value: string
  ) => {
    setRecServices((currentServices) =>
      currentServices.map((service, idx) => {
        if (idx === serviceIndex) {
          return {
            ...service,
            parts: service.parts.map((part, pIndex) => {
              if (pIndex === partIndex) {
                let newValue: any = value

                if (field === 'quantity') {
                  const inputValue = newValue.replace(/[^0-9]/g, '')
                  if (inputValue === '') {
                    newValue = '0'
                  } else {
                    const numberValue = parseInt(inputValue, 10)
                    newValue = numberValue
                  }
                } else if (field === 'price_per_unit') {
                  const input = value.replace(/[^0-9]/g, '')

                  const number = parseInt(input, 10) / 100

                  newValue = new Intl.NumberFormat('en-US', {
                    style: 'currency',
                    currency: 'USD',
                    minimumFractionDigits: 2,
                    maximumFractionDigits: 2,
                  })
                    .format(number)
                    .slice(1)
                    .replace(/,/g, '')
                }

                return { ...part, [field]: newValue }
              }
              return part
            }),
          }
        }
        return service
      })
    )
  }

  const handleAddService = () => {
    const newService: RecommendedService = {
      // TODO: this is going to cause bugs
      id: ``,
      name: '',
      type: '',
      types: [],
      price: 0,
      serviceType: '',
      labor_hours: 0,
      parts: [],
      imageFiles: [],
      uploadedImageFiles: [],
      additional_data: null,
      labor_price: 0,
    }
    setRecServices((prevServices) => [...prevServices, newService])
  }

  const handleRemoveService = (serviceId: string, serviceType: string | null) => {
    setRecServices((prevServices) =>
      prevServices.filter(
        (service) => `${service.id}_${service.type}` !== `${serviceId}_${serviceType}`
      )
    )
    setServicePrices((prevPrices) => {
      const updatedPrices = { ...prevPrices }
      delete updatedPrices[`${serviceId}_${serviceType}`]
      return updatedPrices
    })
  }

  const handleAddServicePart = (serviceIndex: number) => {
    setRecServices((prevServices) =>
      prevServices.map((service, index) =>
        index === serviceIndex
          ? {
              ...service,
              parts: [
                ...service.parts,
                {
                  name: '',
                  quantity: 1,
                  price_per_unit: 0,
                  number: '',
                },
              ],
            }
          : service
      )
    )
  }

  const handleImagesUpload = useCallback((files: File[], index: number) => {
    setRecServices((currentServices) =>
      currentServices.map((service, idx) => {
        if (idx === index) {
          return {
            ...service,
            imageFiles: files,
          }
        }
        return service
      })
    )
  }, [])

  const handleRemoveServicePart = (serviceIndex: number, partIndex: number) => {
    setRecServices((prevServices) => {
      const updatedServices = [...prevServices]
      updatedServices[serviceIndex].parts.splice(partIndex, 1)
      return updatedServices
    })
  }

  const handleAdditionalDataChange = useCallback(
    (index: number, field: string, value: string | string[] | number) => {
      //   changes values in service.additional_data
      //   @ts-ignore
      setRecServices((currentServices) =>
        currentServices.map((service, idx) => {
          if (idx === index) {
            return {
              ...service,
              additional_data: {
                ...service.additional_data,
                [field]: value,
              },
            }
          }
          return service
        })
      )
    },
    []
  )

  const [uploadServiceImages] = useUploadRecServiceImagesMutation()

  const handleSubmit = async () => {
    const filteredServices = showAdditionalServices
      ? recServices.filter((service) => service.serviceType !== 'DIAGNOSIS')
      : []

    setAlertOpen(false)

    const formattedServices = filteredServices.map((service) => {
      const additionalData = service.additional_data
        ? Object.keys(service.additional_data).reduce((acc, key) => {
            // @ts-ignore
            if (service.additional_data[key] && service.additional_data[key] !== '') {
              // @ts-ignore
              acc[key] = service.additional_data[key]
            }
            const numberFieldsSet = new Set([
              'COLD_CRANKING_AMPS',
              'FACTORY_COLD_CRANKING_AMPS',
              'WARRANTY',
            ])
            if (numberFieldsSet.has(key)) {
              // @ts-ignore
              acc[key] = Number(service.additional_data[key])
            }
            return acc
          }, {})
        : null

      return {
        service_id: service.type || service.id,
        labor_hours: Number(service.labor_hours),
        parts: service.parts.map((part) => ({
          name: part.name,
          quantity: Number(part.quantity),
          price_per_unit: Number(part.price_per_unit),
          number: part.number,
        })),
        additional_data: additionalData,
        price: 0,
      }
    })

    const payload = {
      supply_fee: parseFloat(shopSupplyFees?.replace(/[^0-9.-]+/g, '')) || 0,
      tax: parseFloat(tax?.replace(/[^0-9.-]+/g, '')) || 0,
      services: formattedServices,
      invoice_number: invoiceNumber,
    }

    setIsLoading(true)

    try {
      await Promise.all(
        recServices.map(async (service, index) => {
          if (service.imageFiles && service.imageFiles.length > 0) {
            const res = await uploadServiceImages({
              offer_id: offer.id,
              service_id: service.type === null || service.type === '' ? service.id : service.type,
              images: service.imageFiles,
            })
            if (res.error) {
              throw res.error
            }
          }
          return
        })
      )
    } catch (uploadError) {
      let errorMessage = uploadError
      if (uploadError && typeof uploadError === 'object' && 'data' in uploadError) {
        if (
          uploadError.data &&
          typeof uploadError.data === 'object' &&
          'message' in uploadError.data
        ) {
          errorMessage = uploadError.data.message
        }
      }
      console.error('Error uploading file:', errorMessage)
      setUploadStatusWorkOrder('ERROR')
      setUploadStatusInspection('ERROR')
      setUploadStatusPmi('ERROR')
      setIsLoading(false)
      setAlertOpen(true)
      setAlertText(`${JSON.stringify(errorMessage)}`)
      return
    }
    dispatch<any>(submitTechnicalApproval({ offerId: offer.id, body: payload }))
      .then(unwrapResult)
      .then((data: any) => {
        setIsLoading(false)
        onSubmit()
        navigate(0)
      })
      .catch((submitError) => {
        console.error('Error submitting for technician approval:', submitError)
        setUploadStatusWorkOrder('ERROR')
        setUploadStatusInspection('ERROR')
        setUploadStatusPmi('ERROR')
        setIsLoading(false)
        setAlertOpen(true)
        setAlertText(`${JSON.stringify(submitError)}`)
      })
  }

  const [isFormValid, setIsFormValid] = useState(false)

  useEffect(() => {
    const isValid = recServices.every(
      (service) => validateRecommendedServiceFields(services, service).isValid
    )
    setIsFormValid(isValid)
  }, [recServices, offer.labor_rate, services])

  const renderInspectionFeedbackFile = () => {
    if (!inspectionFeedbackFile) return null

    return (
      <MaterialBox
        sx={{
          mt: 2,
          mb: 2,
          p: 2,
          border: 1,
          borderColor: '#FFB38C',
          borderRadius: 'borderRadius',
          bgcolor: 'background.paper',
          width: '100%',
          ...(!showAdditionalServices && { margin: 'auto' }),
        }}
      >
        <Typography variant="h5" sx={{ fontWeight: 'bold', mb: 2, color: '#FF6600' }}>
          Carma Technician Feedback:
        </Typography>
        <MaterialBox
          sx={{
            width: '50%',
            padding: 2,
            boxShadow: 3,
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
          }}
        >
          <MaterialBox sx={{ display: 'flex', alignItems: 'center' }}>
            <PdfDownloadIcon />
            <Typography variant="body2" sx={{ ml: 2 }}>
              {inspectionFeedbackFile.name} ({inspectionFeedbackFile.size})
            </Typography>
          </MaterialBox>
          <Button
            color="warning"
            href={inspectionFeedbackFile.url}
            download
            startIcon={<DownloadIcon />}
            target="_blank"
          />
        </MaterialBox>
      </MaterialBox>
    )
  }

  const handleFeeChange = (newShopSupplyFee) => {
    const input = newShopSupplyFee.replace(/[^0-9]/g, '')
    const number = parseInt(input, 10) / 100

    const formattedNumber = new Intl.NumberFormat('en-US', {
      style: 'currency',
      currency: 'USD',
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    })
      .format(number)
      .slice(1)

    setShopSupplyFees(formattedNumber)
  }

  const handleTaxChange = (newTax: string) => {
    const input = newTax.replace(/[^0-9]/g, '')
    const number = parseInt(input, 10) / 100

    const formattedNumber = new Intl.NumberFormat('en-US', {
      style: 'currency',
      currency: 'USD',
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    })
      .format(number)
      .slice(1)

    setTax(formattedNumber)
  }

  const renderSummaryAdditionalServices = () => {
    if (offer.services.length === 0) {
      return null
    }

    return (
      <MaterialBox
        sx={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'flex-end',
        }}
        mt={2}
        mb={2}
      >
        <MaterialBox display="flex" alignItems="center" sx={{ marginLeft: 'auto', mb: 1 }}>
          <Typography
            sx={{
              fontSize: '16px',
              fontFamily: APP_FONT,
              fontWeight: '600',
              color: '#393A3D',
              marginRight: '8px',
            }}
          >
            Shop supply fees:
          </Typography>
          <Typography
            sx={{
              fontSize: '20px',
              fontFamily: APP_FONT,
              fontWeight: '600',
              color: '#393A3D',
            }}
          >
            {offer.supply_fee && formatPrice(offer.supply_fee)}
          </Typography>
        </MaterialBox>
        <MaterialBox display="flex" alignItems="center" sx={{ marginLeft: 'auto', mb: 1 }}>
          <Typography
            sx={{
              fontSize: '16px',
              fontFamily: APP_FONT,
              fontWeight: '600',
              color: '#393A3D',
              marginRight: '8px',
            }}
          >
            Tax:
          </Typography>
          <Typography
            sx={{
              fontSize: '20px',
              fontFamily: APP_FONT,
              fontWeight: '600',
              color: '#393A3D',
            }}
          >
            {offer.tax && formatPrice(offer.tax)}
          </Typography>
        </MaterialBox>
        <MaterialBox display="flex" alignItems="center" sx={{ marginLeft: 'auto', mb: 8 }}>
          <Typography
            sx={{
              fontSize: '16px',
              fontFamily: APP_FONT,
              fontWeight: '600',
              color: '#393A3D',
              marginRight: '8px',
            }}
          >
            Subtotal:
          </Typography>
          <Typography
            sx={{
              fontSize: '20px',
              fontFamily: APP_FONT,
              fontWeight: '600',
              color: '#DB5D08',
            }}
          >
            {subtotalRequestedServices && formatPrice(subtotalRequestedServices)}
          </Typography>
        </MaterialBox>
      </MaterialBox>
    )
  }

  const renderAdditionalServicesBlock = () => {
    return (
      <MaterialStack>
        <MaterialBox sx={{ display: 'flex', justifyContent: 'space-between' }}>
          <Typography variant="h5" sx={{ fontWeight: 'bold', mb: 3, mt: 3, color: '#FF6600' }}>
            Additional recommended services
          </Typography>

          <MaterialBox>
            {' '}
            <Button
              variant="contained"
              onClick={() => setShowAdditionalServices(false)}
              sx={{
                width: 'fit-content',
                borderRadius: '20px',
                textTransform: 'none',
                fontWeight: 'bold',
                paddingLeft: '32px',
                paddingRight: '32px',
                backgroundColor: '#fff',
                mt: 3,
                color: '#000',
                '&:hover': {
                  backgroundColor: '#e0e0e0',
                },
                '&:active': {
                  backgroundColor: '#grey',
                  color: '#fff',
                },
              }}
            >
              <Close /> <Typography sx={{ ml: 2 }}> Remove additional services</Typography>
            </Button>
          </MaterialBox>
        </MaterialBox>

        <Grid container spacing={2}>
          <Grid item xs={12}>
            {' '}
            {!isPmi && (
              <>
                <Typography variant="h6" sx={{ fontWeight: 'bold' }}>
                  Vehicle inspection (optional)
                </Typography>
                {renderDroppableArea('INSPECTION')}
              </>
            )}
          </Grid>
          <Grid item xs={12}>
            {/*<Typography variant="h6" sx={{ fontWeight: 'bold' }}>*/}
            {/*  Work order authorization report for additional recommended services*/}
            {/*</Typography>*/}
            {/*<Typography sx={{ color: 'text.secondary', marginBottom: 2 }}>*/}
            {/*  Includes services required to resolve issues identified during vehicle inspection.*/}
            {/*</Typography>*/}
            {/*{renderDroppableArea('WORK_ORDER')}*/}
            <Typography variant="h6" sx={{ fontWeight: 'bold' }}>
              Services
            </Typography>
            <Typography sx={{ color: 'text.secondary', marginBottom: 2 }}>
              List all the services which customer’s vehicle needs.
            </Typography>
            {recServices.map((service, index) => {
              return (
                <RecServiceBlock
                  service={service}
                  recServices={recServices}
                  key={`${service.id}_${index}`}
                  index={index}
                  services={services}
                  requestedServices={requestedServices}
                  servicePrices={servicePrices}
                  offer={offer}
                  handleServiceChange={handleServiceChange}
                  handleServicePartChange={handleServicePartChange}
                  handleRemoveService={handleRemoveService}
                  handleAddServicePart={handleAddServicePart}
                  handleImagesChange={handleImagesUpload}
                  refetchImages={refetchExistingFiles}
                  handleRemoveServicePart={handleRemoveServicePart}
                  handleAdditionalDataChange={handleAdditionalDataChange}
                />
              )
            })}
            <Button
              onClick={handleAddService}
              variant="outlined"
              color="warning"
              sx={{ mt: 1, textTransform: 'none' }}
            >
              <AddIcon />
              <span style={{ margin: '4px', paddingLeft: '10px', color: '#FF6600' }}>
                Add service
              </span>
            </Button>
            <MaterialBox
              sx={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                width: '100%',
              }}
            >
              {' '}
              <Button
                variant="contained"
                color="warning"
                disabled={
                  (isPmi && !filePmi) ||
                  (isPmi && uploadStatusPmi !== 'IDLE' && uploadStatusPmi !== 'SUCCESS') ||
                  isLoading ||
                  !isFormValid
                }
                onClick={() => {
                  if (recServices.length > 0 && showAdditionalServices === true) {
                    setEnteringShopSupplyAndTaxEntry(true)
                  } else {
                    handleUpload()
                  }
                }}
                sx={{
                  width: 'fit-content',
                  borderRadius: '20px',
                  textTransform: 'none',
                  fontWeight: 'bold',
                  paddingLeft: '32px',
                  paddingRight: '32px',
                }}
              >
                {recServices.length > 0 && showAdditionalServices === true
                  ? 'Continue'
                  : 'Submit for technician approval'}
              </Button>
            </MaterialBox>
          </Grid>
        </Grid>
      </MaterialStack>
    )
  }

  const renderRequestedServicesBlock = () => {
    return (
      <MaterialStack>
        {renderInspectionFeedbackFile()}
        <MaterialStack
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: showAdditionalServices ? 'none' : 'none',
          }}
        >
          <Typography variant="h6" sx={{ fontWeight: 'bold', mb: 1 }}>
            Requested services
          </Typography>

          {offer.services
            .filter((service) => service.is_approved)
            .map((service, index) => (
              <MaterialBox key={`${service.id}_${service.type}_${index}`}>
                <RequestedServiceInfoBlock requestedService={service} offer={offer} />
                {index < offer.services.length && <Divider />}
              </MaterialBox>
            ))}
          {isPmi && (
            <>
              <Typography variant="h6" sx={{ fontWeight: 'bold' }}>
                PMI report
              </Typography>
              <Typography sx={{ color: 'text.secondary', marginBottom: 2 }}>
                Includes the results of the Preventative Maintenance Inspection (PMI).
              </Typography>
              {renderDroppableArea('PMI')}
            </>
          )}
          {showAdditionalServices && renderSummaryAdditionalServices()}
        </MaterialStack>
      </MaterialStack>
    )
  }

  const isPmi = offer.services.find((service) => service.service.required_data?.includes('PMI'))

  return (
    <Dialog
      open={open}
      onClose={onClose}
      fullWidth
      maxWidth="xl"
      PaperProps={{
        style: {
          maxHeight: '90vh',
        },
      }}
    >
      {isLoading ? (
        <Backdrop
          sx={{
            zIndex: (theme) => theme.zIndex.drawer + 2,
            height: '120vh',
            bgcolor: 'rgba(0, 0, 0, 0.5)',
          }}
          open={true}
          onClick={() => {}}
        >
          <CircularProgress
            size={60}
            sx={{
              color: '#FF6600',
              position: 'absolute',
              top: '50%',
              left: '50%',
              marginTop: '-30px',
              marginLeft: '-30px',
            }}
          />
        </Backdrop>
      ) : (
        <>
          <MaterialBox>
            <ModalTableHead
              onHide={onClose}
              service_request={{
                slug: offer.consumer_request.slug,
                vehicle: offer.vehicle,
                services: offer.services,
                drop_off_time: offer.drop_off_by,
                is_diagnostic: offer.consumer_request.type === 'WORK_ORDER',
                offer_amount: calculateOfferAmount(offer),
                pick_up_time: offer.pick_up_after,
                comment: offer.consumer_request.additional_information,
                status_text: statusText(offer),
              }}
            />
          </MaterialBox>
          {alertOpen && <Alert severity="error">{alertText}</Alert>}
          <DialogContent style={{ overflow: 'auto', maxHeight: '80vh' }}>
            {enteringShopSupplyAndTaxEntry ? (
              <SubmitShopSupplyFeesAndTax
                tax={tax}
                shopSupplyFees={shopSupplyFees}
                handleTaxChange={handleTaxChange}
                handleShopSupplyFeeChange={handleFeeChange}
                requestedServices={offer.services}
                recServices={recServices}
                onExit={() => {
                  setEnteringShopSupplyAndTaxEntry(false)
                }}
                onSubmit={handleUpload}
                isValid={
                  !(
                    (isPmi && !filePmi) ||
                    (isPmi && uploadStatusPmi !== 'IDLE' && uploadStatusPmi !== 'SUCCESS') ||
                    isLoading ||
                    !isFormValid
                  )
                }
                total={totalFee}
                laborRate={offer.labor_rate || 0}
              />
            ) : (
              <div>
                <MaterialStack
                  sx={{
                    display: 'flex',
                    width: '100%',
                    flexDirection: showAdditionalServices ? 'row' : 'column',
                    justifyContent: showAdditionalServices ? 'center' : 'none',
                    alignItems: showAdditionalServices ? 'none' : 'center',
                  }}
                >
                  <Stack w="100%" maw="500px" align="stretch">
                    <Box>
                      <Typography variant="h6" sx={{ fontWeight: 'bold', mb: 1 }}>
                        Your Offer
                      </Typography>
                      <InvoiceNumberInput
                        invoiceNumber={invoiceNumber}
                        onInvoiceNumberChange={setInvoiceNumber}
                      />
                    </Box>
                    {renderRequestedServicesBlock()}
                  </Stack>
                  {showAdditionalServices ? (
                    <MaterialStack sx={{ width: '100%' }}>
                      <Divider orientation="vertical" flexItem />
                      <div style={{ width: '100%', paddingLeft: '20px' }}>
                        {renderAdditionalServicesBlock()}
                      </div>
                    </MaterialStack>
                  ) : (
                    <>
                      <MaterialStack sx={{ width: '100%' }}>
                        <>
                          <Typography
                            sx={{
                              fontSize: '16px',
                              fontWeight: '700',
                              textAlign: 'center',
                              mb: 3,
                              mt: 3,
                              color: '#393A3D',
                            }}
                          >
                            Does this vehicle need any other services? If so, use the button below
                            to add them.
                          </Typography>

                          <MaterialStack sx={{ alignItems: 'center' }}>
                            <Button
                              variant="contained"
                              color="warning"
                              onClick={() => setShowAdditionalServices(true)}
                              sx={{
                                width: 'fit-content',
                                borderRadius: '50px',
                                textTransform: 'none',
                                fontWeight: 'bold',
                                paddingLeft: '32px',
                                paddingRight: '32px',
                                paddingY: '8px',
                                display: 'flex',
                                alignItems: 'center',
                              }}
                            >
                              <PlusIcon style={{ color: '#fff  !important', marginRight: '8px' }} />
                              <span>Recommend additional services</span>
                            </Button>

                            <Button
                              variant="outlined"
                              disabled={
                                (isPmi && !filePmi) ||
                                (uploadStatusWorkOrder !== 'IDLE' &&
                                  uploadStatusWorkOrder !== 'SUCCESS')
                              }
                              color="warning"
                              onClick={async () => {
                                setInspectionWithoutServicesModalOpen(true)
                              }}
                              sx={{
                                width: 'fit-content',
                                borderRadius: '50px',
                                textTransform: 'none',
                                fontWeight: 'bold',
                                paddingLeft: '32px',
                                paddingRight: '32px',
                                paddingY: '8px',
                                mt: 4,
                                mb: 2,
                                borderColor: '#FF6600',
                                color: '#FF6600',
                                borderWidth: '2px',
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'center',
                              }}
                            >
                              Continue without recommending any services
                              <ArrowForwardIcon sx={{ marginLeft: '8px' }} />{' '}
                            </Button>
                          </MaterialStack>
                        </>
                      </MaterialStack>
                    </>
                  )}
                </MaterialStack>
              </div>
            )}
          </DialogContent>
          <InspectionWithoutServicesModal
            isOpen={isInspectionWithoutServicesModalOpen}
            setIsOpen={setInspectionWithoutServicesModalOpen}
            offer={offer}
            onClose={() => setInspectionWithoutServicesModalOpen(false)}
            onConfirmWithoutService={() => {
              handleUpload()
            }}
          />
          <ErrorHandler error={error} clearError={clearError} />
        </>
      )}
    </Dialog>
  )
}

export default InspectionUploadModal
