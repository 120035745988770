import { Box as MaterialBox, List, ListItem, Typography } from '@mui/material'
import { APP_FONT } from '../../../constants/app_font'
import {
  ConsumerRequest,
  ProviderRequestServiceAdditionalDataType,
} from '../../../models/offer_new'
import HoverPopper from '../../../components/hover/hover_popper'
import {
  tirePositionMeasurementReadableNameMapping,
  tirePositionsToMeasurementMapping,
} from '../tables/modals/service.validation'
import { Box, Text } from '@mantine/core'

type ServiceListService = {
  service: {
    name: string
    parent?: { parent_id: string; name: string }
    type: string | 'TIRE'
  }
  is_approved?: boolean
  additional_data?: ProviderRequestServiceAdditionalDataType | null
}

type ServiceListOfferOrRequest = {
  vehicle: { tires?: { width: number; ratio: number; construction: string; diameter: number }[] }
  services: Array<ServiceListService>
  consumer_request?: ConsumerRequest
  type?: string
}

const generateDisplayName = (
  service: ServiceListService,
  offerOrRequest: ServiceListOfferOrRequest
) => {
  let displayName = service.service.name

  if (service.service.parent && service.service.parent.parent_id && service.service.parent.name) {
    displayName = `${service.service.parent.name} (${displayName})`
  }

  return displayName
}

export const getAdditionalDataLines = ({
  service,
  offerOrRequest,
  showMeasurements = false,
}: {
  service: ServiceListService
  offerOrRequest: ServiceListOfferOrRequest
  showMeasurements?: boolean
}): { label: string; value: string }[] => {
  const { additional_data } = service
  if (additional_data === null || additional_data === undefined) {
    return []
  }
  const additionalDataLines: { label: string; value: string }[] = []
  if (
    service.service.type === 'TIRE' &&
    offerOrRequest !== undefined &&
    offerOrRequest.vehicle.tires &&
    offerOrRequest.vehicle.tires.length > 0
  ) {
    additionalDataLines.push({
      label: `Tire size`,
      value: `${offerOrRequest.vehicle.tires[0].width}/${offerOrRequest.vehicle.tires[0].ratio}${offerOrRequest.vehicle.tires[0].construction}${offerOrRequest.vehicle.tires[0].diameter}`,
    })
  }
  if (showMeasurements) {
    if (additional_data.LF_PAD) {
      additionalDataLines.push({ label: 'LF pad', value: additional_data.LF_PAD })
    }
    if (additional_data.RF_PAD) {
      additionalDataLines.push({ label: 'RF pad', value: additional_data.RF_PAD })
    }
    if (additional_data.LR_PAD) {
      additionalDataLines.push({ label: 'LF pad', value: additional_data.LR_PAD })
    }
    if (additional_data.RR_PAD) {
      additionalDataLines.push({ label: 'RF pad', value: additional_data.RR_PAD })
    }
  }
  if (additional_data.TIRE_POSITIONS && additional_data.TIRE_POSITIONS.length > 0) {
    additionalDataLines.push({
      label: '# of tires to be replaced',
      value: `${additional_data.TIRE_POSITIONS.length}`,
    })
    additionalDataLines.push({
      label: 'Tires to be replaced',
      value: additional_data.TIRE_POSITIONS.join(', '),
    })
  }
  if (additional_data.TIRE_BRAND) {
    additionalDataLines.push({ label: `Tire brand`, value: additional_data.TIRE_BRAND })
  }
  if (additional_data.TIRE_MODEL) {
    additionalDataLines.push({ label: 'Model', value: additional_data.TIRE_MODEL })
  }
  if (additional_data.WARRANTY) {
    additionalDataLines.push({
      label: 'Warranty',
      value: `${additional_data.WARRANTY.toLocaleString()} miles`,
    })
  }
  if (showMeasurements) {
    if (additional_data.TIRE_POSITIONS && additional_data.TIRE_POSITIONS.length > 0) {
      for (const tirePosition of additional_data.TIRE_POSITIONS) {
        const key = tirePositionsToMeasurementMapping[tirePosition]
        const label = tirePositionMeasurementReadableNameMapping[key]
        const measurement = additional_data[key]
        if (measurement) {
          additionalDataLines.push({
            label: label,
            value: measurement,
          })
        }
      }
    }
    if (additional_data.COLD_CRANKING_AMPS) {
      additionalDataLines.push({
        label: 'Cold cranking amps',
        value: additional_data.COLD_CRANKING_AMPS,
      })
    }
    if (additional_data.FACTORY_COLD_CRANKING_AMPS) {
      additionalDataLines.push({
        label: 'Factory cold cranking amps',
        value: additional_data.FACTORY_COLD_CRANKING_AMPS,
      })
    }
  }
  return additionalDataLines
}

const ShowMoreServiceInformation = ({
  service,
  offerOrRequest,
  showMeasurements = false,
}: {
  service: ServiceListService
  offerOrRequest: ServiceListOfferOrRequest
  showMeasurements?: boolean
}) => {
  const { additional_data } = service
  if (additional_data === null || additional_data === undefined) {
    return
  }
  const additionalDataLines: { label: string; value: string }[] = getAdditionalDataLines({
    service,
    offerOrRequest,
    showMeasurements,
  })
  if (additionalDataLines.length === 0) {
    return
  }
  return (
    <Box>
      {additionalDataLines.map(({ label, value }) => {
        return (
          <Text
            style={{
              fontWeight: '400',
              fontSize: 12,
              lineHeight: 1.1,
              fontFamily: 'Figtree',
              width: '200px',
            }}
          >
            <strong style={{color: '#ff6600'}}>{label}:</strong> {value}
          </Text>
        )
      })}
    </Box>
  )
}

const ServiceList = ({ offerOrRequest }: { offerOrRequest: ServiceListOfferOrRequest }) => {
  // is_approved is undefined for services on a request
  const filteredServices = offerOrRequest.services.filter(
    (service) => service.is_approved !== false
  )

  const services: ServiceListService[] =
    offerOrRequest.consumer_request?.type === 'DIAGNOSTIC' || offerOrRequest?.type === 'DIAGNOSTIC'
      ? [
          ...filteredServices,
          {
            service: { name: 'Full general diagnostic', type: 'DIAGNOSIS' },
            additional_data: null,
            is_approved: true,
          },
        ]
      : filteredServices

  return (
    <>
      <List sx={{ listStyleType: 'disc', paddingLeft: '20px' }}>
        {services.slice(0, 3).map((service, index) => {
          const displayName = generateDisplayName(service, offerOrRequest)
          return (
            <ListItem
              key={`a-${service.service.name}-${index}`}
              sx={{ display: 'list-item', padding: 0 }}
            >
              <Typography mb={1.5} fontFamily={APP_FONT} fontSize={'14px'}>
                {displayName}
                <ShowMoreServiceInformation service={service} offerOrRequest={offerOrRequest} />
              </Typography>
            </ListItem>
          )
        })}
      </List>
      {services.length > 3 && (
        <HoverPopper
          holder={
            <Typography
              sx={{
                color: '#DB5D08',
                textAlign: 'center',
                cursor: 'pointer',
              }}
            >
              {`(${services.length}) View all`}
            </Typography>
          }
        >
          <MaterialBox
            sx={{
              backgroundColor: 'rgba(238, 238, 238, 0.95)',
              pointerEvents: 'auto',
              paddingTop: 3,
              paddingLeft: 3,
              paddingRight: 3,
              paddingBottom: 1,
            }}
          >
            {services.map((service, index) => {
              const displayName = generateDisplayName(service, offerOrRequest)

              return (
                <Typography
                  key={`a-${service.service.name}-${index}`}
                  mb={1.5}
                  fontFamily={APP_FONT}
                  fontSize={'14px'}
                >
                  {displayName}
                </Typography>
              )
            })}
          </MaterialBox>
        </HoverPopper>
      )}
    </>
  )
}

export default ServiceList
