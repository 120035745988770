const InvoiceNumberCellText = ({ invoiceNumber }: { invoiceNumber?: string }) => {
  if (invoiceNumber) {
    if (invoiceNumber?.length > 10) {
      return invoiceNumber.slice(0, 10) + '...';
    }
    return invoiceNumber;
  }
  return 'N/A'
}

export default InvoiceNumberCellText
