import React, { useState } from 'react'
import {
  TableHead,
  TableRow,
  TableCell,
  IconButton,
  TableBody,
  TableContainer,
  styled,
  Table,
  Box,
} from '@mui/material'
import CloseIcon from '@mui/icons-material/Close'
import { APP_FONT } from '../../../../../constants/app_font'
import Copy from '../../../../../assets/images/contentCopy.svg'
import CopyFilled from '../../../../../assets/images/contentCopyFilled.svg'
import { calculateTimeLeft, formatDateTime } from '../../../../../utils/helper'
import { ArrowTooltip } from './ui/arrow_tooltip'
import { formatPrice } from '../../../../../components/helper/helper'
import { CommentCell } from './comments_cell'
import HoverPopper from '../../../../../components/hover/hover_popper'
import { InfoChip } from './ui/info_chip'
import { CountdownTimer } from '../countdown_timer'

type SpecialHandling =
  | {
      title: string
      description: string
      special_handling: undefined
    }
  | {
      title: undefined
      description: undefined
      special_handling: { description: string; title: string }
    }

interface TableHeadProps {
  onHide: () => void
  service_request: {
    slug: string
    vehicle: {
      name?: string | null | undefined
      year: number | string
      make: string
      model: string
      trim: string
      vin: string | null
      class: {
        name: string
      }
      engines: string[]
      engine_size: number | string | null
      special_handlings: Array<SpecialHandling>
    }
    services: any[]
    drop_off_time: string | null
    pick_up_time?: string
    is_diagnostic?: boolean
    offer_amount?: number
    comment?: string
    status_text?: string
    expires_at?: string
  }
}

const StyledButton = styled(IconButton)`
  & {
    width: 28px;
    height: 28px;
  }
  ,
  &&:hover {
    width: 28px;
    height: 28px;
    background-color: #fff0eb;
  }
`

const ModalTableHead: React.FC<TableHeadProps> = ({ onHide, service_request }) => {
  const [copiedVin, setCopiedVin] = useState<string | null>(null)
  const handleCopyVin = (event: React.MouseEvent, vin: string) => {
    event.stopPropagation()

    navigator.clipboard
      .writeText(vin)
      .then(() => {
        setCopiedVin(vin)
        setTimeout(() => setCopiedVin(null), 3000)
      })
      .catch((err) => console.error('Failed to copy text: ', err))
  }

  const columns = [
    { headerValue: 'Request ID' },
    { headerValue: 'Vehicle ID' },
    { headerValue: 'Vehicle' },
    { headerValue: 'VIN' },
    { headerValue: 'Vehicle class' },
    { headerValue: 'Engine type' },
    { headerValue: 'Engine size' },
    ...(service_request.offer_amount !== undefined ? [{ headerValue: 'Offer amount' }] : []),
    { headerValue: 'Drop-off time', sx: { whiteSpace: 'nowrap' } },
    ...(service_request.pick_up_time
      ? [{ headerValue: 'Pick-up time', sx: { whiteSpace: 'nowrap' } }]
      : []),
    { headerValue: 'Comments' },
    { headerValue: 'Special handling requirements' },
    ...(service_request.status_text ? [{ headerValue: 'Service status' }] : []),
    ...(service_request.expires_at ? [{ headerValue: 'Offer period' }] : []),
  ]

  return (
    <TableContainer>
      <Table size="small" sx={{ width: '100%' }}>
        <TableHead sx={{ backgroundColor: '#FF6600' }}>
          <TableRow>
            {columns.map((column) => {
              return (
                <HeaderCell key={column.headerValue} value={column.headerValue} sx={column.sx} />
              )
            })}
            <TableCell align="right">
              <IconButton
                aria-label="close"
                color="inherit"
                size="medium"
                sx={{ color: 'white' }}
                onClick={onHide}
              >
                <CloseIcon fontSize="inherit" />
              </IconButton>
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          <TableRow>
            <TableCell align="left" sx={{ fontSize: 14, fontFamily: APP_FONT, width: 50 }}>
              {service_request.slug}
            </TableCell>
            <TableCell align="left" sx={{ fontSize: 14, fontFamily: APP_FONT }}>
              {service_request.vehicle.name}
            </TableCell>
            <TableCell align="left" sx={{ fontSize: 14, fontFamily: APP_FONT }}>
              {service_request.vehicle.year} {service_request.vehicle.make}{' '}
              {service_request.vehicle.model} {service_request.vehicle.trim ?? ''}
            </TableCell>
            <TableCell align="left" sx={{ fontSize: 14, fontFamily: APP_FONT }}>
              <div style={{ display: 'flex', alignItems: 'center', flexWrap: 'nowrap' }}>
                {(service_request.vehicle?.vin && `${service_request.vehicle.vin}`) ||
                  'Not provided'}
                {service_request.vehicle?.vin && (
                  <ArrowTooltip title={copiedVin === service_request.vehicle?.vin ? 'Copied!' : ''}>
                    <StyledButton
                      onClick={(event: any) =>
                        handleCopyVin(event, service_request.vehicle?.vin ?? '')
                      }
                    >
                      <img
                        src={copiedVin === service_request.vehicle?.vin ? CopyFilled : Copy}
                        alt=""
                      />
                    </StyledButton>
                  </ArrowTooltip>
                )}
              </div>
            </TableCell>
            <TableCell align="left" sx={{ fontSize: 14, fontFamily: APP_FONT }}>
              {service_request.vehicle?.class.name}
            </TableCell>
            <TableCell align="left" sx={{ fontSize: 14, fontFamily: APP_FONT }}>
              {service_request.vehicle.engines.join(', ')}
            </TableCell>
            <TableCell align="left" sx={{ fontSize: 14, fontFamily: APP_FONT }}>
              {service_request.vehicle.engine_size}
            </TableCell>
            {service_request.offer_amount !== undefined && (
              <TableCell align="left" sx={{ fontSize: 14, fontFamily: APP_FONT }}>
                {formatPrice(service_request.offer_amount)}
              </TableCell>
            )}
            <TableCell align="left" sx={{ fontSize: 14, fontFamily: APP_FONT, width: 100 }}>
              <Box>
                {service_request.drop_off_time && formatDateTime(service_request.drop_off_time)}
              </Box>
            </TableCell>
            {service_request.pick_up_time && (
              <TableCell align="left" sx={{ fontSize: 14, fontFamily: APP_FONT }}>
                {service_request.pick_up_time && formatDateTime(service_request.pick_up_time)}
              </TableCell>
            )}
            <TableCell align="left" sx={{ fontSize: 14, fontFamily: APP_FONT }}>
              <CommentCell comment={service_request.comment} maxLength={30} />
            </TableCell>
            <TableCell sx={{ fontFamily: APP_FONT }} align="left">
              <div style={{ display: 'flex', alignItems: 'center', flexWrap: 'nowrap' }}>
                {service_request.vehicle.special_handlings &&
                service_request.vehicle.special_handlings.length > 0 ? (
                  <SpecialHandlingCell
                    special_handling={service_request.vehicle.special_handlings[0]}
                  />
                ) : (
                  'N/A'
                )}
              </div>
            </TableCell>
            {service_request.status_text && (
              <TableCell style={{ fontFamily: APP_FONT }} align="left">
                {service_request.status_text}
              </TableCell>
            )}

            {service_request.expires_at && (
              <TableCell sx={{ fontFamily: APP_FONT }} align="left">
                {service_request.expires_at && (
                  <CountdownTimer
                    initialTime={calculateTimeLeft(service_request.expires_at)}
                    short
                  />
                )}
              </TableCell>
            )}
            <TableCell />
          </TableRow>
        </TableBody>
      </Table>
    </TableContainer>
  )
}

const HeaderCell = ({ sx, value }: { sx?: any; value: string }) => {
  return (
    <TableCell
      align="left"
      sx={{
        fontWeight: '600',
        fontFamily: APP_FONT,
        fontSize: 14,
        color: 'white',
        ...sx,
      }}
    >
      {value}
    </TableCell>
  )
}

const SpecialHandlingCell = ({ special_handling }: { special_handling: SpecialHandling }) => {
  const title = special_handling.title ?? special_handling.special_handling?.title
  const description = special_handling.description ?? special_handling.special_handling?.description
  return (
    <HoverPopper holder={<InfoChip label={title} />}>
      <Box
        sx={{
          width: '500px',
          textAlign: 'center',
          backgroundColor: 'rgba(238, 238, 238, 0.95)',
          pointerEvents: 'auto',
          paddingTop: 1,
          paddingLeft: 3,
          paddingRight: 3,
          paddingBottom: 1,
        }}
      >
        {description}
      </Box>
    </HoverPopper>
  )
}

export default ModalTableHead
