import { usePlacesAutocomplete } from './usePlacesAutoComplete'
import { Autocomplete, TextField } from '@mui/material'

type PlacesAutocompleteProps = {
  onChange: (value: string) => void
  error?: boolean
  helperText?: string
}

export const PlacesAutocomplete = ({ onChange, error, helperText }: PlacesAutocompleteProps) => {
  const { loading, suggestions, handleChange, value } = usePlacesAutocomplete()

  const handleSelect = async (address: string | null) => {
    // clearSuggestions();
    if (address !== null) {
      onChange(address)
    }
  }

  const handleInputChange = (newValue: string) => {
    if (newValue) {
      handleChange(newValue)
    }
  }

  return (
    <Autocomplete
      disablePortal
      id="address-autocomplete"
      options={suggestions}
      sx={{ width: '100%' }}
      getOptionLabel={(option) => option}
      onChange={(event, newValue) => handleSelect(newValue)}
      renderInput={(params) => (
        <TextField
          {...params}
          onChange={(e) => handleInputChange(e.target.value)}
          label="Address"
          error={error}
          helperText={helperText}
        />
      )}
    />
  )
}
