import React, { useState, useEffect } from 'react'
import {
  Box,
  Table,
  TableBody,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Toolbar,
  styled,
  TableCell,
} from '@mui/material'
import { useDispatch } from 'react-redux'
import { unwrapResult } from '@reduxjs/toolkit'
import { ServiceRequest } from '../../../models/service_request'
import { IconButton, Typography } from '@mui/material'
import socket from '../../../sockets/socket'
import { APP_FONT } from '../../../constants/app_font'
import { ReactComponent as RequestIcon } from '../../../assets/images/missed_request_icon.svg'
import { fetchMissedServiceRequests } from '../../../redux/actions/service_request'
import Copy from '../../../assets/images/contentCopy.svg'
import CopyFilled from '../../../assets/images/contentCopyFilled.svg'
import { formatDateTime } from '../../../utils/helper'
import { ArrowTooltip } from './modals/components/ui/arrow_tooltip'
import HoverPopper from '../../../components/hover/hover_popper'
import { formatPrice } from '../../../components/helper/helper'
import ServiceList from '../components/service_list'

type Props = {
  formatCurrency: any
}

const StickyHeaderTableCell = styled(TableCell)(({ theme }) => ({
  backgroundColor: theme.palette.background.paper,
  top: 0,
  zIndex: 10,
  position: 'sticky',
  boxShadow: '0 2px 2px -1px rgba(0,0,0,0.4)',
}))

const StyledButton = styled(IconButton)`
  & {
    width: 35px;
    height: 35px;
    margin: 12px;
  }
  ,
  &&:hover {
    width: 35px;
    height: 35px;
    background-color: #fff0eb;
    margin: 12px;
  }
`

export default function EnhancedTable(props: Props) {
  const { formatCurrency } = props
  const [currentRequests, setCurrentRequests] = React.useState<ServiceRequest[]>([])
  const [alert, setAlert] = useState(false)
  const [selectedTimeblock, setSelectedTimeblock] = useState()
  const [copiedVin, setCopiedVin] = React.useState<string | null>(null)
  const [copiedComment, setCopiedComment] = React.useState<string | null>(null)
  const dispatch = useDispatch()

  const getNewSRs = () => {
    const providerId = localStorage.getItem('branchId')
    if (providerId)
      dispatch<any>(fetchMissedServiceRequests({ providerId }))
        .then(unwrapResult)
        .then((current_requests: any) => {
          setCurrentRequests(current_requests)
        })
        .catch((rejectedValueOrSerializedError: any) => {
          console.log('error')
        })
  }

  const getOfferStatus = (sr: ServiceRequest) => {
    if (sr.status?.status.toLowerCase() === 'cancelled') {
      return 'Canceled request'
    } else if (sr.status?.status.toLowerCase() === 'expired' && sr.offers && sr.offers.length > 0) {
      return 'Request expired: no offer was accepted'
    } else if (
      sr.status?.status.toLowerCase() === 'expired' &&
      (!sr.offers || sr.offers.length === 0)
    ) {
      return 'Request expired: no shops made offers'
    } else if (
      sr.offers.length > 0 &&
      sr.status?.status.toLowerCase() !== 'cancelled' &&
      sr.status?.status.toLowerCase() !== 'expired'
    ) {
      return 'Accepted another offer'
    }
  }

  const getBidAmount = (sr: ServiceRequest) => {
    const offer = sr.offers?.find((el) => el.is_accepted)
    if (!offer && sr.status?.status.toLowerCase() !== 'completed') {
      return '-'
    } else if (sr.type === 'DIAGNOSTIC' && offer) {
      return offer?.provider_branch?.labor_rate
        ? `Labor rate: ${formatPrice(offer?.provider_branch?.labor_rate)} per hour`
        : 'N/A'
    } else if (
      offer &&
      sr.status?.status.toLowerCase() !== 'cancelled' &&
      sr.status?.status.toLowerCase() !== 'expired'
    ) {
      return formatCurrency.format(offer?.total_fee || 0)
    }
  }

  useEffect(() => {
    getNewSRs()
    const providerId = localStorage.getItem('branchId')
    if (providerId) {
      socket.emit('subscribe', { branchId: providerId })
      socket.on('status-update', (payload) => {
        getNewSRs()
      })
    }

    return () => {
      socket.off('status-update')
    }
  }, [])

  const [open, setOpen] = React.useState(false)

  const EnhancedTableToolbar = () => {
    return (
      <Toolbar
        sx={{
          pl: { sm: 2 },
          pr: { xs: 1, sm: 1 },
          marginTop: 5,
        }}
      >
        <Typography
          sx={{ flex: '1 1 100%' }}
          variant="h6"
          id="tableTitle"
          component="div"
        ></Typography>
      </Toolbar>
    )
  }

  const handleClose = () => {
    setOpen(false)
    setSelectedTimeblock(undefined)
    setAlert(false)
  }

  const handleCopyVin = (event: React.MouseEvent, vin: string) => {
    event.stopPropagation()

    navigator.clipboard
      .writeText(vin)
      .then(() => {
        setCopiedVin(vin)
        setTimeout(() => {
          setCopiedVin(null)
        }, 3000)
      })
      .catch((err) => console.error('Failed to copy text: ', err))
  }

  const handleCopyComment = (event: React.MouseEvent, comment: string) => {
    event.stopPropagation()

    navigator.clipboard
      .writeText(comment)
      .then(() => {
        setCopiedComment(comment)
        setTimeout(() => {
          setCopiedComment(null)
        }, 3000)
      })
      .catch((err) => console.error('Failed to copy text: ', err))
  }

  return (
    <Box sx={{ mb: 5 }}>
      <Box
        sx={{
          marginLeft: 2,
          display: 'flex',
          alignItems: 'center',
          gap: 1,
          marginBottom: -13,
        }}
      >
        <RequestIcon />
        <Typography
          sx={{
            marginTop: 4,
            fontFamily: APP_FONT,
            fontSize: 14,
            letterSpacing: 1,
            textTransform: 'uppercase',
            color: '#AEAEAE',
          }}
          id="tableTitle"
          component="div"
        >
          Missed Requests
        </Typography>
      </Box>
      <Paper>
        <EnhancedTableToolbar />
        <TableContainer sx={{ px: 2, maxHeight: 800, overflowY: 'auto' }}>
          <Table size={'small'}>
            <TableHead>
              <TableRow>
                <StickyHeaderTableCell
                  sx={{ fontWeight: '600', fontFamily: APP_FONT, whiteSpace: 'nowrap' }}
                  align="left"
                >
                  Request ID
                </StickyHeaderTableCell>
                <StickyHeaderTableCell
                  sx={{ fontWeight: '600', fontFamily: APP_FONT, whiteSpace: 'nowrap' }}
                  align="left"
                >
                  Created on
                </StickyHeaderTableCell>
                <StickyHeaderTableCell
                  sx={{ fontWeight: '600', fontFamily: APP_FONT, whiteSpace: 'nowrap' }}
                  align="left"
                >
                  Vehicle
                </StickyHeaderTableCell>
                <StickyHeaderTableCell
                  sx={{ fontWeight: '600', fontFamily: APP_FONT, whiteSpace: 'nowrap' }}
                  align="left"
                >
                  Vehicle ID
                </StickyHeaderTableCell>
                <StickyHeaderTableCell
                  sx={{ fontWeight: '600', fontFamily: APP_FONT, whiteSpace: 'nowrap' }}
                  align="left"
                >
                  VIN
                </StickyHeaderTableCell>
                <StickyHeaderTableCell
                  sx={{ fontWeight: '600', fontFamily: APP_FONT, whiteSpace: 'nowrap' }}
                  align="left"
                >
                  Vehicle class
                </StickyHeaderTableCell>
                <StickyHeaderTableCell
                  sx={{ fontWeight: '600', fontFamily: APP_FONT, whiteSpace: 'nowrap' }}
                  align="left"
                >
                  Engine type
                </StickyHeaderTableCell>
                <StickyHeaderTableCell
                  sx={{ fontWeight: '600', fontFamily: APP_FONT, whiteSpace: 'nowrap' }}
                  align="left"
                >
                  Engine size
                </StickyHeaderTableCell>
                <StickyHeaderTableCell
                  sx={{ fontWeight: '600', fontFamily: APP_FONT, whiteSpace: 'nowrap' }}
                  align="left"
                >
                  Services
                </StickyHeaderTableCell>
                <StickyHeaderTableCell
                  sx={{ fontWeight: '600', fontFamily: APP_FONT, whiteSpace: 'nowrap' }}
                  align="left"
                >
                  Reason
                </StickyHeaderTableCell>
                <StickyHeaderTableCell
                  sx={{ fontWeight: '600', fontFamily: APP_FONT, whiteSpace: 'nowrap' }}
                  align="left"
                >
                  Comments
                </StickyHeaderTableCell>
                <StickyHeaderTableCell
                  sx={{ fontWeight: '600', fontFamily: APP_FONT, whiteSpace: 'nowrap' }}
                  align="left"
                >
                  Winning offer
                </StickyHeaderTableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {currentRequests?.map((request: ServiceRequest) => {
                request.services =
                  request.offers
                    ?.find((offer): any => offer.is_accepted === true)
                    ?.services.filter((service: any) => service.is_approved === true) ||
                  request.services

                return (
                  <TableRow key={request.id}>
                    <TableCell sx={{ fontFamily: APP_FONT }} align="left">
                      {request.slug ?? request.id}
                    </TableCell>
                    <TableCell style={{ fontFamily: APP_FONT }} align="left">
                      {formatDateTime(request.created_at)}
                    </TableCell>
                    <TableCell sx={{ fontFamily: APP_FONT }} align="left">{`${
                      request.vehicle?.year
                    } ${request.vehicle?.make} ${request.vehicle?.model} ${
                      request.vehicle.trim ?? ''
                    }`}</TableCell>
                    <TableCell sx={{ fontFamily: APP_FONT }}>
                      <div style={{ maxWidth: '125px' }}>{request.vehicle?.name}</div>
                    </TableCell>
                    <TableCell sx={{ fontFamily: APP_FONT }} align="left">
                      <div style={{ display: 'flex', alignItems: 'center', flexWrap: 'nowrap' }}>
                        {request.vehicle?.vin || 'VIN not provided'}
                        {request.vehicle?.vin && (
                          <ArrowTooltip
                            style={{ margin: 0 }}
                            title={copiedVin === request.vehicle?.vin ? 'Copied!' : ''}
                          >
                            <StyledButton
                              onClick={(event) => handleCopyVin(event, request.vehicle?.vin ?? '')}
                            >
                              <img
                                src={copiedVin === request.vehicle?.vin ? CopyFilled : Copy}
                                alt=""
                              />
                            </StyledButton>
                          </ArrowTooltip>
                        )}
                      </div>
                    </TableCell>
                    <TableCell sx={{ fontFamily: APP_FONT }} align="left">
                      {request.vehicle?.class?.name ? request.vehicle?.class?.name : ''}
                    </TableCell>
                    <TableCell sx={{ fontFamily: APP_FONT }} align="left">
                      {[request.vehicle?.engines].join(', ')}
                    </TableCell>
                    <TableCell sx={{ fontFamily: APP_FONT }} align="left">
                      {request.vehicle?.engine_size}
                    </TableCell>
                    <TableCell sx={{ fontFamily: APP_FONT }} align="left">
                      <ServiceList offerOrRequest={request} />
                    </TableCell>
                    <TableCell sx={{ fontFamily: APP_FONT }} align="left">
                      {getOfferStatus(request)}
                    </TableCell>
                    <TableCell sx={{ fontFamily: APP_FONT }} align="left">
                      <div style={{ display: 'flex', alignItems: 'center', maxWidth: '175px' }}>
                        {request.additional_information ? (
                          <>
                            {request.additional_information.length > 104 ? (
                              <HoverPopper
                                holder={
                                  <span>
                                    {request.additional_information.substring(0, 20)}
                                    <span>...</span>
                                  </span>
                                }
                              >
                                <Box
                                  sx={{
                                    width: '500px',
                                    textAlign: 'center',
                                    backgroundColor: 'rgba(238, 238, 238, 0.95)',
                                    pointerEvents: 'auto',
                                    p: 2,
                                  }}
                                >
                                  {request.additional_information}
                                </Box>
                              </HoverPopper>
                            ) : (
                              request.additional_information
                            )}
                            <ArrowTooltip
                              style={{ margin: 0 }}
                              title={
                                copiedComment === request.additional_information ? 'Copied!' : ''
                              }
                            >
                              <StyledButton
                                onClick={(e: any) =>
                                  handleCopyComment(e, request.additional_information ?? '')
                                }
                              >
                                <img
                                  src={
                                    copiedComment === request.additional_information
                                      ? CopyFilled
                                      : Copy
                                  }
                                  alt="Copy icon"
                                />
                              </StyledButton>
                            </ArrowTooltip>
                          </>
                        ) : (
                          'No additional information'
                        )}
                      </div>
                    </TableCell>
                    <TableCell sx={{ fontFamily: APP_FONT }} align="left">
                      {getBidAmount(request)}
                    </TableCell>
                  </TableRow>
                )
              })}
            </TableBody>
          </Table>
        </TableContainer>
      </Paper>
    </Box>
  )
}
