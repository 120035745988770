import { Typography, Box as MaterialBox, Stack } from '@mui/material'
import { Offer, ProviderRequestServiceType } from '../../../../../models/offer_new'
import { APP_FONT } from '../../../../../constants/app_font'
import { ReactComponent as DownloadIcon } from '../../../../../assets/images/download.svg'
import { formatPrice, sanitizeNumber } from '../../../../../components/helper/helper'
import Decimal from 'decimal.js'
import { getAdditionalDataLines } from '../../../components/service_list'
import { Box } from '@mantine/core'

const AdditionalDataInfo = ({ label, value }: { label: string; value: string }) => {
  return (
    <Box display="flex" mt={0}>
      <Typography
        variant="body1"
        style={{
          fontWeight: 'bold',
          fontFamily: APP_FONT,
          fontSize: 12,
          marginRight: '5px',
        }}
      >
        {label}:
      </Typography>
      <Typography
        variant="body1"
        style={{ fontWeight: 'regular', fontFamily: APP_FONT, fontSize: 12 }}
      >
        {value}
      </Typography>
    </Box>
  )
}

const RequestedServiceInfoBlock = ({
  requestedService,
  offer,
}: {
  requestedService: ProviderRequestServiceType
  offer: Offer
}) => {
  const additionalDataLines = getAdditionalDataLines({
    service: requestedService,
    offerOrRequest: offer,
    showMeasurements: false,
  })

  return (
    <MaterialBox
      sx={{
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        mb: 1,
        mt: '6px',
      }}
    >
      <Stack spacing={1}>
        <Typography
          sx={{
            fontSize: '16px',
            fontFamily: APP_FONT,
            fontWeight: '600',
            color: '#393A3D',
            mt: 1,
          }}
        >
          {requestedService.service.parent?.parent_id
            ? requestedService.service.parent.name
            : requestedService.service.name}
        </Typography>
        {additionalDataLines.map(({ label, value }) => (
          <AdditionalDataInfo key={label} label={label} value={value} />
        ))}
        <Typography variant="body2" color="textSecondary" sx={{ fontFamily: APP_FONT }}>
          {requestedService.service.parent?.parent_id && requestedService.service.name}
        </Typography>
        {requestedService.service.required_data?.includes('PMI') && (
          <a
            href="https://joincarma-images.s3.amazonaws.com/PMI+final.pdf"
            download
            target="_blank"
            style={{
              display: 'flex',
              alignItems: 'center',
              textDecoration: 'none',
              color: '#FF6600',
            }}
            rel="noreferrer"
          >
            <Typography
              fontFamily={APP_FONT}
              fontSize={16}
              mr={1}
              style={{
                fontSize: '16px',
                fontFamily: APP_FONT,
                fontWeight: '600',
                color: '#FF6600',
              }}
            >
              PMI Download Link
            </Typography>
            <DownloadIcon color="#FF6600" />
          </a>
        )}
      </Stack>
      <Typography
        sx={{
          fontSize: '20px',
          fontFamily: APP_FONT,
          fontWeight: '600',
          color: '#393A3D',
        }}
      >
        {formatPrice(
          new Decimal(sanitizeNumber(requestedService.labor_hours))
            .mul(offer.labor_rate ?? 0)
            .toDecimalPlaces(2)
        )}
      </Typography>
    </MaterialBox>
  )
}

export default RequestedServiceInfoBlock
