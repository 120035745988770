import { createAsyncThunk } from '@reduxjs/toolkit'
import { apiCall } from '../../apis/api'
import { CreateOfferPayload, EditOffer, FetchOffers, UpdateOffer } from '../../models/offer'
import { ActionTypes } from '../constants/action-types'
import axios from 'axios'

export const fetchOffers = createAsyncThunk(
  ActionTypes.FETCH_OFFERS,
  async (params: FetchOffers, { rejectWithValue }) => {
    try {
      const data = await apiCall(
        `provider/request/${params.providerId}`,
        'post',
        { statuses: params.statuses },
        undefined
      )
      return data
    } catch (error: any) {
      if (error.response && error.response.data.message) {
        return rejectWithValue(error.response.data.message)
      } else {
        return rejectWithValue(error.message)
      }
    }
  }
)

export const createOffer = createAsyncThunk(
  ActionTypes.CREATE_OFFER,
  async (body: CreateOfferPayload, { rejectWithValue }) => {
    try {
      const data = await apiCall('/provider/offer', 'post', body, undefined)
      return data
    } catch (error: any) {
      if (error.response && error.response.data.message) {
        return rejectWithValue(error.response.data.message)
      } else {
        return rejectWithValue(error.message)
      }
    }
  }
)

export const updateOfferStatus = createAsyncThunk(
  ActionTypes.UPDATE_OFFER,
  async (creds: UpdateOffer, { rejectWithValue }) => {
    try {
      const data = await apiCall(`/provider/offer/${creds.id}`, 'put', creds, undefined)
      return data
    } catch (error: any) {
      if (error.response && error.response.data.message) {
        return rejectWithValue(error.response.data.message)
      } else {
        return rejectWithValue(error.message)
      }
    }
  }
)

export const updateOffer = createAsyncThunk(
  ActionTypes.UPDATE_OFFER,
  async (creds: EditOffer, { rejectWithValue }) => {
    try {
      const { id, ...body } = creds
      const data = await apiCall(`/provider/offer/${id}`, 'patch', body, undefined)
      return data
    } catch (error: any) {
      if (error.response && error.response.data.message) {
        return rejectWithValue(error.response.data.message)
      } else {
        return rejectWithValue(error.message)
      }
    }
  }
)

export const uploadPdf = createAsyncThunk(
  ActionTypes.UPLOAD_PDF,
  async (data: any, { rejectWithValue }) => {
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_NEST_JS_API}provider/offer/${data.offerId}/file?type=${
          data.fileType
        }${data.out_mileage ? '&out_mileage=' + data.out_mileage : ''}`,
        data.formData,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('userToken')}`,
            'Content-Type': 'multipart/form-data',
          },
        }
      )
      return response.data
    } catch (error: any) {
      if (error.response && error.response.data.message) {
        return rejectWithValue(error.response.data.message)
      } else {
        return rejectWithValue(error.message || 'An unknown error occurred')
      }
    }
  }
)

export const createReccomendedServices = createAsyncThunk(
  ActionTypes.UPDATE_OFFER,
  async (data: any, { rejectWithValue }) => {
    try {
      const response = await apiCall(
        `provider/offer/${data.offerId}/recommend-services`,
        'post',
        data.body,
        undefined
      )
      return response
    } catch (error: any) {
      if (error.response && error.response.data.message) {
        return rejectWithValue(error.response.data.message)
      } else {
        return rejectWithValue(error.message)
      }
    }
  }
)

type SubmitTechnicianApprovalPaylod = {
  supply_fee?: number
  tax?: number
  pick_up_after?: string
  diagnostic_result?: string
  services: OfferServiceDto[]
  invoice_number?: string
}

type OfferServiceDto = {
  service_id: string
  additional_data?: Record<string, any> | null // TODO: does null cause issues? we're already sending it, so I guess not
  labor_hours?: number
  price?: number
  parts?: CreateOfferServicePartDto[]
}

type CreateOfferServicePartDto = {
  name: string
  quantity: number
  price_per_unit: number
  number: string
}

export const submitTechnicalApproval = createAsyncThunk(
  ActionTypes.UPDATE_OFFER,
  async (data: { offerId: string; body: SubmitTechnicianApprovalPaylod }, { rejectWithValue }) => {
    try {
      const response = await apiCall(
        `provider/offer/${data.offerId}/submit-technician-approval`,
        'post',
        data.body,
        undefined
      )
      return response
    } catch (error: any) {
      if (error.response && error.response.data.message) {
        return rejectWithValue(error.response.data.message)
      } else {
        return rejectWithValue(error.message)
      }
    }
  }
)

export const updateOfferRevisedTax = createAsyncThunk(
  ActionTypes.UPDATE_OFFER_REVISED_TAX,
  async (data: any, { rejectWithValue }) => {
    try {
      const response = await apiCall(
        `provider/offer/${data.offerId}/add-revised-tax`,
        'patch',
        data.body,
        undefined
      )
      return response
    } catch (error: any) {
      if (error.response && error.response.data.message) {
        return rejectWithValue(error.response.data.message)
      } else {
        return rejectWithValue(error.message)
      }
    }
  }
)

export const getServicelist = createAsyncThunk(
  ActionTypes.UPDATE_OFFER_REVISED_TAX,
  async (data: any, { rejectWithValue }) => {
    try {
      const response = await apiCall(
        `provider/offer/${data.offerId}/add-revised-tax`,
        'patch',
        data.body,
        undefined
      )
      return response
    } catch (error: any) {
      if (error.response && error.response.data.message) {
        return rejectWithValue(error.response.data.message)
      } else {
        return rejectWithValue(error.message)
      }
    }
  }
)
