import React, { useState, useEffect } from 'react'
import styled from 'styled-components'
import { LfMeasurementSelect } from './LfMeasurementSelect'
import { LrMeasurementSelect } from './LrMeasurementSelect'
import { LrInsideMeasurementSelect } from './LrInsideMeasurementSelect'
import { RfMeasurementSelect } from './RfMeasurementSelect'
import { RrMeasurementSelect } from './RrMeasurementSelect'
import { RrInsideMeasurementSelect } from './RrInsideMeasurementSelect'
import { Typography } from '@mui/material'
import { APP_FONT } from '../../../../../../constants/app_font'
import Checkbox from '@mui/material/Checkbox'
import { CheckBox, CheckBoxOutlineBlank } from '@mui/icons-material'

const Row = styled.div<{ count?: number }>`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 10px;
  gap: 10px;
  & > div {
    flex: '1';
  }
`

const CustomCheckbox = (props) => {
  return (
    <Checkbox
      icon={<CheckBoxOutlineBlank sx={{ color: '#ff6600' }} />}
      checkedIcon={<CheckBox sx={{ color: '#ff6600' }} />}
      {...props}
    />
  )
}

export type TirePositionsFields =
  | 'LF_MEASUREMENT'
  | 'LR_MEASUREMENT'
  | 'RF_MEASUREMENT'
  | 'RR_MEASUREMENT'
  | 'LR_INSIDE_MEASUREMENT'
  | 'RR_INSIDE_MEASUREMENT'

type TirePositionsProps = {
  values: {
    [key in TirePositionsFields]: string
  }
  tirePositions: string[]
  onTirePositionsChange: (tirePositions: string[]) => void
  onChange: (field: TirePositionsFields, value: string) => void
}

export const TirePositions = ({
  values,
  onChange,
  tirePositions,
  onTirePositionsChange,
}: TirePositionsProps) => {
  const [selectedTires, setSelectedTires] = useState<Set<string>>(new Set(tirePositions))

  useEffect(() => {
    onTirePositionsChange(Array.from(selectedTires))
  }, [selectedTires])

  const toggleSelectedTires = (tire: string) => {
    const updatedTires = new Set(selectedTires)
    if (updatedTires.has(tire)) {
      updatedTires.delete(tire)
      onChange(`${tire}_MEASUREMENT` as TirePositionsFields, '')
    } else {
      updatedTires.add(tire)
    }
    setSelectedTires(updatedTires)
  }

  const isTireSelected = (tire: string) => {
    return selectedTires.has(tire)
  }

  const tireFields = [
    { name: 'LF', measurement: 'LF_MEASUREMENT', Component: LfMeasurementSelect },
    { name: 'LR', measurement: 'LR_MEASUREMENT', Component: LrMeasurementSelect },
    {
      name: 'LR_INSIDE',
      measurement: 'LR_INSIDE_MEASUREMENT',
      Component: LrInsideMeasurementSelect,
    },
    { name: 'RF', measurement: 'RF_MEASUREMENT', Component: RfMeasurementSelect },
    { name: 'RR', measurement: 'RR_MEASUREMENT', Component: RrMeasurementSelect },
    {
      name: 'RR_INSIDE',
      measurement: 'RR_INSIDE_MEASUREMENT',
      Component: RrInsideMeasurementSelect,
    },
  ]

  const selectedTireFields = tireFields.filter((tire) => isTireSelected(tire.name))

  return (
    <div>
      <Typography
        sx={{
          fontFamily: APP_FONT,
          marginRight: '8px',
          fontSize: '14px',
          marginTop: '16px',
          marginBottom: '16px',
        }}
      >
        Please select tires that need to be replaced and their current tread depths:
      </Typography>
      <Row>
        <Row style={{ flex: 1 }} count={selectedTireFields.length}>
          <CustomCheckbox
            checked={isTireSelected('LF')}
            onChange={() => toggleSelectedTires('LF')}
          />
          <Typography
            sx={{
              fontFamily: APP_FONT,
              marginRight: '8px',
              fontSize: '15px',
            }}
          >
            LF
          </Typography>
          <LfMeasurementSelect
            disabled={!isTireSelected('LF')}
            value={values.LF_MEASUREMENT}
            onChange={(value) => onChange('LF_MEASUREMENT', value)}
          />
        </Row>
        <Row style={{ flex: 1 }} count={selectedTireFields.length}>
          <CustomCheckbox
            checked={isTireSelected('LR')}
            onChange={() => toggleSelectedTires('LR')}
          />
          <Typography
            sx={{
              fontFamily: APP_FONT,
              marginRight: '8px',
              fontSize: '15px',
            }}
          >
            LR
          </Typography>
          <LrMeasurementSelect
            disabled={!isTireSelected('LR')}
            value={values.LR_MEASUREMENT}
            onChange={(value) => onChange('LR_MEASUREMENT', value)}
          />
        </Row>
        <Row style={{ flex: 1 }} count={selectedTireFields.length}>
          <CustomCheckbox
            checked={isTireSelected('LR_INSIDE')}
            onChange={() => toggleSelectedTires('LR_INSIDE')}
          />
          <Typography
            sx={{
              fontFamily: APP_FONT,
              marginRight: '8px',
              fontSize: '15px',
            }}
          >
            LR Inside
          </Typography>
          <LrInsideMeasurementSelect
            disabled={!isTireSelected('LR_INSIDE')}
            value={values.LR_INSIDE_MEASUREMENT}
            onChange={(value) => onChange('LR_INSIDE_MEASUREMENT', value)}
          />
        </Row>
      </Row>
      <Row>
        <Row style={{ flex: 1 }} count={selectedTireFields.length}>
          <CustomCheckbox
            checked={isTireSelected('RF')}
            onChange={() => toggleSelectedTires('RF')}
          />
          <Typography
            sx={{
              fontFamily: APP_FONT,
              marginRight: '8px',
              fontSize: '15px',
            }}
          >
            RF
          </Typography>
          <RfMeasurementSelect
            disabled={!isTireSelected('RF')}
            value={values.RF_MEASUREMENT}
            onChange={(value) => onChange('RF_MEASUREMENT', value)}
          />
        </Row>
        <Row style={{ flex: 1 }} count={selectedTireFields.length}>
          <CustomCheckbox
            checked={isTireSelected('RR')}
            onChange={() => toggleSelectedTires('RR')}
          />
          <Typography
            sx={{
              fontFamily: APP_FONT,
              marginRight: '8px',
              fontSize: '15px',
            }}
          >
            RR
          </Typography>
          <RrMeasurementSelect
            disabled={!isTireSelected('RR')}
            value={values.RR_MEASUREMENT}
            onChange={(value) => onChange('RR_MEASUREMENT', value)}
          />
        </Row>
        <Row style={{ flex: 1 }} count={selectedTireFields.length}>
          <CustomCheckbox
            checked={isTireSelected('RR_INSIDE')}
            onChange={() => toggleSelectedTires('RR_INSIDE')}
          />
          <Typography
            sx={{
              fontFamily: APP_FONT,
              marginRight: '8px',
              fontSize: '15px',
            }}
          >
            RR Inside
          </Typography>
          <RrInsideMeasurementSelect
            disabled={!isTireSelected('RR_INSIDE')}
            value={values.RR_INSIDE_MEASUREMENT}
            onChange={(value) => onChange('RR_INSIDE_MEASUREMENT', value)}
          />
        </Row>
      </Row>
    </div>
  )
}
