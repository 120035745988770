import React from 'react'
import { TextField } from '@mui/material'

type TireWarrantyMilesInputProps = {
  value: number | string
  onChange: (value: number | string) => void
}

export const TireWarrantyMilesInput = ({ value = '', onChange }: TireWarrantyMilesInputProps) => {
  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const inputValue = event.target.value.replace(/[^0-9]/g, '')
    if (inputValue === '') {
      onChange('')
    } else {
      const numberValue = parseInt(inputValue, 10)
      onChange(numberValue)
    }
  }

  return (
    <TextField
      fullWidth
      label="Warranty (in miles)"
      placeholder="Enter miles"
      type="text"
      value={value !== undefined ? value.toLocaleString('en-US') : ''}
      onChange={handleChange}
      InputProps={{
        style: { background: '#fff', width: '100%' },
      }}
    />
  )
}
